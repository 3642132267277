import React, { FC, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalLogin, globalForgot } from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";
import Input from "../../components/Input";

import { EnumControlSmsFormType } from "../../state/ducks/global/types";

import SmsValidation from "../Cadastro/SmsValidation";

import "./Login.scss";

const Login: FC = () => {
  const dispatch = useDispatch();

  const [showForgotForm, setShowForgotForm] = useState<boolean>(false);
  const [emailForgot, setEmailForgot] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { sending, error, forgot, smsControlForm } = useSelector(
    (state: IRootState) => state.global
  );

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(globalLogin(email, password));
  };

  const submitForgotHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(globalForgot(emailForgot));
  };

  return (
    <div className="login">
      <div className="container">
        <span className="title">
          <b>BEM VINDO A BINGOTINE.</b> FAÇA SEU LOGIN.
        </span>
        {error && smsControlForm !== EnumControlSmsFormType.SmsForm && (
          <div className="msg error">{error}</div>
        )}
        <div className="row flex-m-column">
          <div className="col col-13 col-m-25 col-m-last">
            <div className="wrap-form">
              {smsControlForm === EnumControlSmsFormType.SmsForm && <SmsValidation />}

              {smsControlForm === EnumControlSmsFormType.RegularForm && (
                <form onSubmit={submitHandler}>
                  <div className="col col-25 col-last">
                    <Input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      placeholder="E-mail"
                      required
                    />
                  </div>
                  <div className="col col-25 col-last">
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                      }
                      placeholder="Senha"
                      required
                    />
                  </div>
                  <div className="buttons">
                    <Input
                      type="submit"
                      additionalClass="btn"
                      value={sending ? "CARREGANDO..." : "ENTRAR"}
                      disabled={sending}
                    />
                  </div>
                  <span onClick={() => setShowForgotForm(!showForgotForm)} className="esqueci">
                    Esqueceu a senha? Clique aqui para recuperar
                  </span>
                </form>
              )}

              {forgot.error && <div className="msg error">{forgot.error}</div>}
              {forgot.success && <div className="msg success">Nova senha enviada por e-mail</div>}
              {showForgotForm && (
                <Fragment>
                  <div className="sep"></div>
                  <form onSubmit={submitForgotHandler}>
                    <Input
                      type="email"
                      name="email"
                      value={emailForgot}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmailForgot(e.target.value)
                      }
                      placeholder="Preencha seu e-mail"
                      required
                    />
                    <Input
                      type="submit"
                      additionalClass="btn"
                      value={forgot.isLoading ? "CARREGANDO..." : "REENVIAR SENHA"}
                      disabled={forgot.isLoading}
                    />
                  </form>
                </Fragment>
              )}
            </div>
          </div>
          <div className="col col-12 col-m-25 col-last col-m-last">
            <div className="image-block"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
