import { IReduxAction } from "../../../global";
import {
  ICheckoutItem,
  OPEN_MODAL_CHECKOUT,
  CLOSE_MODAL_CHECKOUT,
  GET_PACKS_REQUEST,
  GET_PACKS_REJECTED,
  GET_PACKS_FULFILLED,
  GET_CUPONS_REQUEST,
  GET_CUPONS_REJECTED,
  GET_CUPONS_FULFILLED,
  CHECKOUT_ORDER_REQUEST,
  CHECKOUT_ORDER_FULFILLED,
  CHECKOUT_ORDER_REJECTED,
  CHECKOUT_PAYMENT_FORM,
  ICheckoutOrderRequest,
  ICheckoutCard,
  IRescueCupomRequest,
  RESCUE_CUPOM_REQUEST,
  RESCUE_CUPOM_SUCCESS,
  RESCUE_CUPOM_REJECTED,
  ICupons,
} from "./types";

export const openModalAction = (): IReduxAction => ({
  type: OPEN_MODAL_CHECKOUT,
});
export const closeModalAction = (): IReduxAction => ({
  type: CLOSE_MODAL_CHECKOUT,
});

export const getPacksRequest = (): IReduxAction => ({
  type: GET_PACKS_REQUEST,
});
export const getPacksFulfilled = (packs: any): IReduxAction => ({
  type: GET_PACKS_FULFILLED,
  payload: { packs },
});
export const getPacksRejected = (error: any): IReduxAction => ({
  type: GET_PACKS_REJECTED,
  payload: { error },
});

export const getCuponsRequest = (): IReduxAction => ({
  type: GET_CUPONS_REQUEST,
});
export const getCuponsFulfilled = (cupons: any): IReduxAction => ({
  type: GET_CUPONS_FULFILLED,
  payload: { cupons },
});
export const getCuponsRejected = (error: any): IReduxAction => ({
  type: GET_CUPONS_REJECTED,
  payload: { error },
});

export const rescueCupomRequest = (codigo: string): IRescueCupomRequest => ({
  type: RESCUE_CUPOM_REQUEST,
  payload: {
    codigo,
  },
});
export const rescueCupomSuccess = (cupom: ICupons): IReduxAction => ({
  type: RESCUE_CUPOM_SUCCESS,
  payload: {
    cupom,
  },
});
export const rescueCupomRejected = (error: any): IReduxAction => ({
  type: RESCUE_CUPOM_REJECTED,
  payload: { error },
});

export const checkoutOrderRequest = (
  checkout: ICheckoutItem[],
  method: string,
  type: string,
  card?: ICheckoutCard
): ICheckoutOrderRequest => ({
  type: CHECKOUT_ORDER_REQUEST,
  payload: { checkout, method, card, type },
});

export const checkoutPaymentForm = (
  items: ICheckoutItem[],
  method: string,
  type: string
): IReduxAction => ({
  type: CHECKOUT_PAYMENT_FORM,
  payload: { items, method, type },
});

export const checkoutOrderFulfilled = (data: any): IReduxAction => ({
  type: CHECKOUT_ORDER_FULFILLED,
  payload: { data },
});
export const checkoutOrderRejected = (error: any): IReduxAction => ({
  type: CHECKOUT_ORDER_REJECTED,
  payload: { error },
});
