import React, { useState } from "react";
/*import QRCode from "qrcode.react"; */
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { IRootState } from "../../../state/ducks/types";
import Button, { EnumButtonVariants } from "../../Button/Button";
import { closeModalAction, tryagainPaymentAction } from "../../../state/ducks/deposits/actions";
import { EnumDepositsTab } from "../../../state/ducks/deposits/types";
import classnames from "classnames";

const OrderSuccess: React.FC = () => {
  const dispatch = useDispatch();
  const { deposito } = useSelector((state: IRootState) => state.deposits);
  const [showPopupPix, setShowPopupPix] = useState<boolean>(false);

  const handleClickPlayButton = () => {
    dispatch(closeModalAction());
  };

  const handleClickTryagainButton = () => {
    dispatch(tryagainPaymentAction());
  };

  const handlerPopupPix = () => {
    setShowPopupPix(!showPopupPix);
  };

  const popupPix = () => {
    return (
      <div className={classnames("wrapPopup", { active: showPopupPix })}>
        <div className="popuppix">
          <span className="close" onClick={() => handlerPopupPix()}>
            X
          </span>
          <h3>💰 Como fazer Pix 💰</h3>
          <h3>Para fazer PIX só seguir o passo a passo:</h3>
          <p>
            Pague por QRCode ou <br />
            Embaixo da imagem clique em <span>PIX COPIA E COLA</span> <br />
            Abra o app do seu banco, procure a opção <span>PIX</span> e depois selecione{" "}
            <span>PIX copia e cola</span>, daí é só colar e concluir o pagamento!
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="order-success">
      {popupPix()}
      {deposito && (
        <>
          <div className="order-success__header">
            {deposito?.depositMpRespone?.status === "approved" && (
              <>
                <h3>Seu pedido nº {deposito.id} foi aprovado com sucesso :)</h3>
                <div className="order-success__content">
                  <br />
                  <p>Seu crédito já está na conta!</p>
                  <br />
                </div>
                <div className="order-success__footer">
                  <Button
                    text="JOGAR"
                    variant={EnumButtonVariants.Secondary}
                    height={64}
                    fontStyle={{
                      fontSize: 28,
                    }}
                    onClick={handleClickPlayButton}
                  />
                </div>
              </>
            )}

            {deposito?.depositMpRespone?.status === "rejected" && (
              <>
                <div className="order-success__content">
                  <h3>Seu pedido nº {deposito.id} não foi aprovado :(</h3>
                </div>
                <div className="order-success__footer">
                  <Button
                    text="TENTE NOVAMENTE"
                    variant={EnumButtonVariants.Secondary}
                    height={64}
                    fontStyle={{
                      fontSize: 28,
                    }}
                    onClick={handleClickTryagainButton}
                  />
                </div>
              </>
            )}

            {deposito?.depositMpRespone?.status !== "approved" &&
              deposito?.depositMpRespone?.status !== "rejected" &&
              deposito?.payment_type !== EnumDepositsTab.PIX && (
                <>
                  <h3>Seu pedido nº {deposito.id} está sendo processado.</h3>
                  <div className="order-success__content">
                    <p>
                      Seu crédito estará na conta assim que for aprovado pela instituição
                      financeira.
                    </p>
                  </div>
                  <div className="order-success__footer">
                    <Button
                      text="FECHAR"
                      variant={EnumButtonVariants.Secondary}
                      height={64}
                      fontStyle={{
                        fontSize: 28,
                      }}
                      onClick={handleClickPlayButton}
                    />
                  </div>
                </>
              )}
          </div>
        </>
      )}
      {deposito?.payment_type === EnumDepositsTab.PIX && (
        <>
          <div className="order-success__header">
            <h3>
              Seu pedido foi realizado com sucesso!{" "}
              <span className="seeMore" onClick={() => handlerPopupPix()}>
                Saiba como fazer o pix
              </span>
            </h3>
            <p>Para fazer o pagamento, faça um PIX com o QR Code abaixo:</p>
          </div>
          <div className="order-success__content">
            <div className="qr-code">
              <img
                src={`data:image/jpeg;base64,${deposito?.depositMpRespone?.qr_code_base64}`}
                alt="QRCode Bingotine"
              />
            </div>
            <CopyToClipboard text={deposito?.depositMpRespone?.qr_code}>
              <span className="link">
                <span className="material-icons copy"></span>
                PIX COPIA E COLA
              </span>
            </CopyToClipboard>
            <p className="copia-cola">
              Abra o APP do seu banco, clique em PIX, procure a opção copia e cola. Caso não tenha
              clique em pagar, coloque pagamento por QrCode e coloque os dados copiados.
            </p>
          </div>
          {/*
          <div className="order-success__footer">
            <p className="no-qr-code">
              Caso o aplicativo do seu banco não reconheça o QR Code, siga as etapas abaixo:
            </p>
            <p>
              1- Faça um pix utilizando a chave{" "}
              <CopyToClipboard text={"pix@bingotine.com.br"}>
                <span className="link">pix@bingotine.com.br</span>
              </CopyToClipboard>{" "}
              <br />
              2- Envie o comprovante para o Whats App:{" "}
              <a
                href="https://api.whatsapp.com/send?phone=595991889257"
                target="_blank"
                rel="noreferrer"
              >
                +595 991 889 257{" "}
              </a>
            </p>
          </div>
          */}
        </>
      )}
    </div>
  );
};

export default OrderSuccess;
