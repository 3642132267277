import React, { FC } from "react";
import Bullet from "../components/Bullet";
import Button from "../components/Button";
import { EnumButtonVariants } from "../components/Button/Button";

const relativeStyle: React.CSSProperties = {
  height: 30,
  position: "relative",
};

const ComponentsLibrary: FC = () => {
  return (
    <>
      <Button text="Button Component" />
      <Button text="Button Component" variant={EnumButtonVariants.Secondary} />
      <Button text="Button Component" variant={EnumButtonVariants.Orange} />
      <div style={relativeStyle}>
        <Bullet n={99} />
      </div>
      <div style={relativeStyle}>
        <Bullet n={100} />
      </div>
    </>
  );
};

export default ComponentsLibrary;
