import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import storage from "redux-persist/lib/storage";

import { globalSaga } from "./global/saga";
import { webSocketSaga } from "./webSocket/saga";
import checkoutSaga from "./checkout/saga";
import { gamesSaga } from "./games/saga";
import { productsSaga } from "./products/saga";
import { depositsSaga } from "./deposits/saga";
import { withdrawSaga } from "./withdraw/saga";
import { gameCardsSaga } from "./gameCards/saga";
import { fastbingoSaga } from "./fastbingo/saga";
//import modalProfileSaga from "./modalProfile/saga";

import globalReducer from "./global/reducer";
import webSocketReducer from "./webSocket/reducer";
import drawBallReducer from "./drawBall/reducer";
import modalWinnerReducer from "./modalWinner/reducer";
import checkoutReducer from "./checkout/reducer";
import gamesReducer from "./games/reducer";
import fastbingoReducer from "./fastbingo/reducer";
import productsReducer from "./products/reducer";
import depositsReducer from "./deposits/reducer";
import withdrawReducer from "./withdraw/reducer";
import gameCardsReducer from "./gameCards/reducer";

// Root Saga
export function* rootSaga() {
  yield all([
    webSocketSaga(),
    globalSaga(),
    checkoutSaga(),
    gamesSaga(),
    fastbingoSaga(),
    productsSaga(),
    depositsSaga(),
    withdrawSaga(),
    gameCardsSaga(),
  ]);
}

const global = persistReducer(
  {
    key: "bingotine-react-global",
    storage,
    whitelist: ["loggedIn", "token", "refreshToken", "cliente", "player"],
  },
  globalReducer
);

// Root Reducer
export const rootReducers = combineReducers({
  global,
  webSocket: webSocketReducer,
  drawBall: drawBallReducer,
  checkout: checkoutReducer,
  modalWinner: modalWinnerReducer,
  games: gamesReducer,
  products: productsReducer,
  deposits: depositsReducer,
  withdraw: withdrawReducer,
  gameCards: gameCardsReducer,
  fastbingo: fastbingoReducer,
});
