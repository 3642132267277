import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import classnames from "classnames";
import { isMobile } from "react-device-detect";
import {
  ROUTE_TROQUENIVEL,
  ROUTE_PERFIL,
  ROUTE_LOGIN,
  ROUTE_INDIQUE,
  ROUTE_HOME,
  ROUTE_CADASTRO,
} from "../../config/routes";

import "./Header.scss";

import { IRootState } from "../../state/ducks/types";
import {
  globalGetNotifications,
  globalGetPlayer,
  globalGetUnreadNotifications,
  globalLogout,
} from "../../state/ducks/global/actions";

import phAvatar from "../../assets/avatar-ph.jpg";
import MenuLoggedIn from "./MenuLoggedIn";
import Button from "../../components/Button";
import LinkSpan from "../../components/Link";
import classNames from "classnames";
import { INotificationsData } from "../../state/ducks/global/types";
import ApiClient from "../../services/ApiClient";
import Notifications from "./Notifications";

const Header: FC = () => {
  const {
    player: { nickname, avatar, level },
    loggedIn,
    notifications: {
      meta: { total_unread },
      data,
    },
  } = useSelector((state: IRootState) => state.global);
  const dispatch = useDispatch();

  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [showMobileNumbers, setShowMobileNumbers] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotificationsData[]>();
  const [toggleNotification, setToggleNotification] = useState(false);
  const [isClosingToggle, setIsClosingToggle] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      dispatch(globalGetPlayer());
      dispatch(globalGetUnreadNotifications());
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    setNotifications(data.slice(0, 5));
  }, [data]);

  useEffect(() => {
    if (toggleNotification && notifications && notifications.length > 0) {
      setTimeout(() => {
        ApiClient.post(`players/notifications/read`, {
          ids: notifications?.map((notification) => notification.id).join(","),
        });
        setTimeout(() => {
          dispatch(globalGetUnreadNotifications());
        }, 500);
      }, 500);
    }
  }, [toggleNotification, notifications, dispatch]);

  const openToggleNotification = (e: React.MouseEvent) => {
    if (isClosingToggle) {
      setIsClosingToggle(false);
    } else {
      e.preventDefault();
      setToggleNotification(true);
      if (notifications?.length === 0) {
        dispatch(globalGetNotifications({ page: 1, per_page: 10 }));
      }
    }
  };

  const [showPopupVideo, setShowPopupVideo] = useState<boolean>(false);
  const [videoPopup, setVideoPopup] = useState<string>("");

  const handlerPopupVideo = (video: string) => {
    setShowPopupVideo(!showPopupVideo);
    setVideoPopup(video);
  };

  const closeToggleNotification = () => {
    if (showPopupVideo) return;
    setToggleNotification(false);
    setIsClosingToggle(true);
    /** workaround */
    setTimeout(() => {
      setIsClosingToggle(false);
    }, 100);
  };

  return (
    <div className="header">
      <div className="main-menu">
        <div className="container">
          <div className="row aic item-first">
            <Link to={ROUTE_HOME} className="wlogo">
              <img src="/logo-bingotine.png" alt="Bingotine" className="logo" />
            </Link>
            {loggedIn && (
              <div className="d-block d-m-none notific">
                <figure className="bell" onClick={openToggleNotification}>
                  <img src="/bell.png" alt="notificaçao" />
                  {total_unread !== 0 && <span>{total_unread}</span>}
                </figure>
              </div>
            )}
            {toggleNotification && (
              <Notifications
                showPopupVideo={showPopupVideo}
                closeToggle={closeToggleNotification}
                videoPopup={videoPopup}
                handlerPopupVideo={handlerPopupVideo}
                notifications={notifications}
              />
            )}
          </div>

          <div className="row align-items-center row-menu">
            {!loggedIn && (
              <div className="row d-m-none btns">
                <Link to={ROUTE_LOGIN}>
                  <Button
                    height={35}
                    text={"ENTRAR"}
                    buttonStyle={{
                      borderRadius: 10,
                      border: "1px solid #FFF",
                      background: "transparent",
                      margin: "0 8px",
                      width: "215px",
                    }}
                    fontStyle={{
                      fontSize: 19,
                      fontWeight: 600,
                      color: "#FFF",
                      padding: "0 15px",
                    }}
                  />
                </Link>
                <Link to={ROUTE_CADASTRO}>
                  <Button
                    height={35}
                    text={"CRIAR CONTA"}
                    buttonStyle={{
                      borderRadius: 10,
                      background: "#49267c",
                      margin: "0 8px",
                      width: "215px",
                    }}
                    fontStyle={{
                      fontSize: 19,
                      fontWeight: 600,
                      color: "#FFF",
                      padding: "0 15px",
                    }}
                  />
                </Link>
              </div>
            )}
            <div className="row align-items-center links">
              {!loggedIn && (
                <div className="d-m-none">
                  <Link to={ROUTE_LOGIN}>
                    <Button
                      children={<img className="object-cover" src="/ic-user.png" alt="Usuário" />}
                    />
                  </Link>
                </div>
              )}

              {loggedIn && (
                <div className="menu-link">
                  <LinkSpan>
                    <div
                      className="bars column justify-content-between"
                      onClick={() => setShowMenuMobile(!showMenuMobile)}
                    >
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                    <span className="text">MENU</span>
                  </LinkSpan>

                  <div
                    className={classNames("float-submenu column", {
                      show: showMenuMobile && isMobile,
                    })}
                  >
                    <div className="d-none d-m-block">
                      <span className="close" onClick={() => setShowMenuMobile(false)}></span>
                    </div>
                    <img src="/logo-bingotine-small.png" alt="Bingotine" className="logo" />
                    <div className="sublinks">
                      <Link to={ROUTE_PERFIL} onClick={() => setShowMenuMobile(false)}>
                        <LinkSpan>Meu Perfil</LinkSpan>
                      </Link>
                      <Link to={ROUTE_TROQUENIVEL} onClick={() => setShowMenuMobile(false)}>
                        <LinkSpan>Troque seu nível por dinheiro</LinkSpan>
                      </Link>
                      <Link to={ROUTE_INDIQUE} onClick={() => setShowMenuMobile(false)}>
                        <LinkSpan>Indique Ganhe</LinkSpan>
                      </Link>
                      <LinkSpan click={() => setShowMobileNumbers(!showMobileNumbers)}>
                        <img src="/whatsapp-purple.png" alt="Contato" /> Contato
                      </LinkSpan>
                      <div className={classNames("column", { "d-none": !showMobileNumbers })}>
                        <a
                          href="https://api.whatsapp.com/send?phone=5511991406564"
                          target="_blank"
                          rel="noreferrer"
                          className="phonelink"
                        >
                          (11) 9.9140-6564
                        </a>
                        <a
                          href="https://api.whatsapp.com/send?phone=5511988830364"
                          target="_blank"
                          rel="noreferrer"
                          className="phonelink"
                        >
                          (11) 9.8883-0364
                        </a>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(globalLogout());
                        }}
                      >
                        <LinkSpan>Sair</LinkSpan>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {loggedIn && (
            <div className="order-3 menu-logged">
              <div className="d-m-none">
                <Link to={ROUTE_PERFIL}>
                  <div className="avatar">
                    <img src={avatar?.image_url?.toString() ?? phAvatar} alt={nickname} />
                    <div className="avatar-level">
                      <div className="label" style={{ backgroundColor: level?.color ?? "#000" }}>
                        {level?.name}
                      </div>
                      {level?.image_url && (
                        <div className="icon">
                          <img src={level?.image_url} alt={level?.name} />
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="d-none d-m-block notific">
                <figure className="bell" onClick={openToggleNotification}>
                  <img src="/bell.png" alt="notificaçao" />
                  {total_unread !== 0 && <span>{total_unread}</span>}
                </figure>
              </div>
            </div>
          )}

          {!loggedIn && (
            <div className="d-m-block d-none order-m-3 user">
              <Link to={ROUTE_LOGIN}>
                <Button
                  children={<img className="object-cover" src="/ic-user.png" alt="Usuário" />}
                  width={32}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className={classnames("menu d-none", {
          "d-m-block": !loggedIn,
          "d-m-none": loggedIn,
        })}
      >
        <div className="container row justify-content-between">
          <div className="d-none d-m-row w-m-100">
            {!loggedIn && (
              <div className="row btns w-m-100">
                <Link to={ROUTE_LOGIN}>
                  <Button
                    height={35}
                    text={"ENTRAR"}
                    buttonStyle={{
                      borderRadius: 10,
                      border: "1px solid #FFF",
                      background: "transparent",
                      margin: "0",
                      width: "100%",
                    }}
                    fontStyle={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#FFF",
                      padding: "0 15px",
                    }}
                  />
                </Link>
                <Link to={ROUTE_CADASTRO}>
                  <Button
                    height={35}
                    text={"CRIAR CONTA"}
                    buttonStyle={{
                      borderRadius: 10,
                      background: "#49267c",
                      margin: "0",
                      width: "100%",
                      border: "none",
                    }}
                    fontStyle={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#FFF",
                      padding: "0 15px",
                    }}
                  />
                </Link>
              </div>
            )}
          </div>
          {loggedIn && <span className="userName">{nickname}</span>}
        </div>
      </div>
      {loggedIn && <MenuLoggedIn />}
    </div>
  );
};

export default Header;
