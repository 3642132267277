import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DepositoLine from "../../../components/DepositoLine";
import { getProducts } from "../../../state/ducks/products/actions";
import {
  postDeposit,
  setSelectedProduct,
  tryagainPaymentAction,
} from "../../../state/ducks/deposits/actions";
import { IRootState } from "../../../state/ducks/types";

import { EnumDepositsTab } from "../../../state/ducks/deposits/types";
import { IProductItems } from "../../../state/ducks/products/types";

import "./Deposits.scss";

const Deposito: FC = () => {
  const dispatch = useDispatch();

  const { products } = useSelector((state: IRootState) => state.products);

  const { error, productId, modal } = useSelector((state: IRootState) => state.deposits);

  const [activeTab, setActiveTab] = useState<number>(1);
  const [paymentMethod, setPaymentMethod] = useState<EnumDepositsTab>(EnumDepositsTab.PIX);

  const [showVideo, setShowVideo] = useState<boolean>(false);

  const handleChangeTab = (tab: number, paymentMethod: EnumDepositsTab) => {
    setActiveTab(tab);
    setPaymentMethod(paymentMethod);
  };

  const handleClickPayment = (productId: number) => {
    if (paymentMethod === EnumDepositsTab.PIX) {
      handlePixPayment(productId);
    } else if (paymentMethod === EnumDepositsTab.CARTAO) {
      handleCardTab(productId);
    }
  };

  const handleCardTab = (productId: number) => {
    dispatch(setSelectedProduct(productId));
  };

  const handlePixPayment = (productId: number) => {
    const params: any = {};
    params.product_id = productId;
    params.payment_type = paymentMethod;
    dispatch(postDeposit(params));
  };

  useEffect(() => {
    if (productId !== undefined && paymentMethod === EnumDepositsTab.CARTAO) {
      dispatch(tryagainPaymentAction());
    }

    if (productId === undefined) {
      //dispatch(emptyFlags());
    }
  }, [dispatch, productId, paymentMethod]);

  useEffect(() => {
    if (modal.isOpen) {
      dispatch(getProducts());
    }
  }, [dispatch, modal]);

  const findValue = (type: string, items: IProductItems[]): string => {
    return items.find((item) => item.type === type)?.amount.toString() ?? "";
  };

  const handlerShowVideo = () => {
    setShowVideo(!showVideo);
  };

  return (
    <div className="deposito">
      {showVideo && (
        <div className="showPixVideo">
          <span className="close" onClick={() => handlerShowVideo()}>
            X
          </span>

          <video src="/como-fazer-pix-bingotine.mp4" controls></video>
        </div>
      )}
      <div className="container">
        {error && <div className="msg error">{error}</div>}
        <div className="wmenu">
          <div className="row flex-m-wrap">
            <div
              className={`menu menu-1 row jcc aic ${activeTab === 1 ? "active" : ""}`}
              onClick={() => handleChangeTab(1, EnumDepositsTab.PIX)}
            >
              <span>Pagar com Pix</span>
            </div>
          </div>
          <span className="how-to-do-pix" onClick={() => handlerShowVideo()}>
            <img src="/pix_ques.png" alt="Pagar com pix" />
            Como fazer PIX?
          </span>
          <div className="menu row jcc aic tac recharge">
            <span>Fazendo qualquer recarga você GANHA giros grátis na Roleta de Bônus! 🤩</span>
          </div>
        </div>
        <div className="wtabs">
          <div className="tab">
            <div className="row head">
              <div className="col col-12 col-margin-0 col-m-17">
                <span>APROVEITE!</span>
              </div>
              <div className="col col-13 col-last col-margin-0 col-m-8 col-m-last">
                <span className="mobile-right">VALOR</span>
              </div>
            </div>
            {products &&
              products.map((item) => (
                <DepositoLine
                  compre={findValue("DEPOSIT", item.productItems)}
                  ganhe={findValue("BONUS", item.productItems)}
                  valor={item.amount}
                  addButton={true}
                  key={item.id}
                  onClick={() => handleClickPayment(item.id)}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposito;
