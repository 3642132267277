import { IReduxAction } from "../../../global";
import {
  DEPOSITS,
  DEPOSITS_ERROR,
  DEPOSITS_FULLFILLED,
  EMPTY_FLAGS,
  OPEN_MODAL_DEPOSITS,
  CLOSE_MODAL_DEPOSITS,
  SET_SELECTED_PRODUCT,
  TRY_AGAIN_PAYMENT,
  IDeposit,
} from "./types";

export const emptyFlags = (): IReduxAction => ({
  type: EMPTY_FLAGS,
});

export const setSelectedProduct = (productId: number): IReduxAction => ({
  type: SET_SELECTED_PRODUCT,
  payload: {
    productId,
  },
});

export const openModalAction = (): IReduxAction => ({
  type: OPEN_MODAL_DEPOSITS,
});
export const closeModalAction = (): IReduxAction => ({
  type: CLOSE_MODAL_DEPOSITS,
});

export const tryagainPaymentAction = (): IReduxAction => ({
  type: TRY_AGAIN_PAYMENT,
});

export const postDeposit = (params: {}): IReduxAction => ({
  type: DEPOSITS,
  payload: {
    ...params,
  },
});

export const getDepositError = (message: string): IReduxAction => ({
  type: DEPOSITS_ERROR,
  payload: {
    message,
  },
});

export const getDepositFullfilled = (deposit: IDeposit): IReduxAction => ({
  type: DEPOSITS_FULLFILLED,
  payload: {
    deposit,
  },
});
