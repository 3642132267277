import Cookies from "universal-cookie";
import moment from "moment";

export const ACCESS_COOKIE = "accessToken";
export const REFRESH_COOKIE = "refreshToken";
export const TYPE_COOKIE = "typeToken";

class Cookie {
  static getCookie(cookie: string): string {
    const cookies = new Cookies();
    return cookies.get(cookie);
  }

  static deleteCookie(cookie: string): void {
    const cookies = new Cookies();

    cookies.remove(cookie, {
      path: "/",
    });
  }

  static saveAuthenticationCookie(token: string, type: string, expires_at: Date): void {
    const cookie = new Cookies();

    cookie.set(ACCESS_COOKIE, token, {
      path: "/",
      expires: moment(new Date(expires_at)).toDate(),
    });

    cookie.set(TYPE_COOKIE, type, {
      path: "/",
      expires: moment(new Date(expires_at)).toDate(),
    });
  }

  static getAuthenticationCookie() {
    return Cookie.getCookie(ACCESS_COOKIE);
  }

  static getTypeCookie() {
    return Cookie.getCookie(TYPE_COOKIE);
  }

  static getReAuthenticationCookie() {
    return Cookie.getCookie(REFRESH_COOKIE);
  }

  static isAuthenticated() {
    return Boolean(Cookie.getAuthenticationCookie());
  }

  static clearTokens() {
    Cookie.deleteCookie(ACCESS_COOKIE);
    Cookie.deleteCookie(REFRESH_COOKIE);
  }
}

export default Cookie;
