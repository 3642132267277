import { IReduxAction } from "../../../global";
import { PRODUCTS, PRODUCTS_ERROR, PRODUCTS_FULLFILLED, IProducts } from "./types";

export const getProducts = (): IReduxAction => ({
  type: PRODUCTS,
});

export const getProductsFullfilled = (products: IProducts[]): IReduxAction => ({
  type: PRODUCTS_FULLFILLED,
  payload: {
    products,
  },
});

export const getProductsError = (message: string): IReduxAction => ({
  type: PRODUCTS_ERROR,
  payload: {
    message,
  },
});
