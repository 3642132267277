import React, { FC, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import Header from "../../containers/Header";
import Footer from "../../containers/Footer";
import Lobby from "../../containers/Lobby";

import "./Screen.scss";
import { IAttributes } from "../../global";
import { IRootState } from "../../state/ducks/types";
import { Redirect } from "react-router-dom";
import { ROUTE_LOGIN } from "../../config/routes";
import { resetCurrentVB } from "../../state/ducks/games/actions";
import { EnumPlayerStatus } from "../../state/ducks/global/types";

interface IScreenProps extends IAttributes {
  withHeader?: boolean;
  withFooter?: boolean;
  validLogin?: boolean;
  withLobby?: boolean;
}

const Screen: FC<IScreenProps> = ({
  withHeader,
  withFooter,
  validLogin,
  withLobby,
  children,
  ...otherAttributes
}) => {
  const {
    loggedIn,
    player: { status },
    finishedLoggeInCheck,
  } = useSelector((state: IRootState) => state.global);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("jogos-de-bingo")) {
      dispatch(resetCurrentVB());
    }
  }, [location, dispatch]);

  return (finishedLoggeInCheck && validLogin && !loggedIn) ||
    (loggedIn && status !== EnumPlayerStatus.ACTIVE && !location.pathname.includes("login")) ? (
    <Redirect to={{ pathname: ROUTE_LOGIN }} />
  ) : (
    <Fragment>
      {withHeader && <Header />}
      {withLobby && (
        <div className="container container-lobby">
          <Lobby />
        </div>
      )}
      <Route {...otherAttributes}>{children}</Route>
      {withFooter && <Footer />}
    </Fragment>
  );
};

export default Screen;
