import { takeLatest, all, call, put } from "redux-saga/effects";
import { WITHDRAW } from "./types";
import { postWithdrawError, postWithdrawFullfilled } from "./actions";

import { withdrawPost } from "./api";
import { rejectApi } from "../../../utils/Functions";

export function* withdrawSaga() {
  yield all([yield takeLatest(WITHDRAW, withdrawData)]);
}

function* withdrawData({ payload }: any) {
  try {
    yield call(withdrawPost, payload);
    yield put(postWithdrawFullfilled());
  } catch (err) {
    const error = rejectApi(err);
    yield put(postWithdrawError(error));
    console.error("error", err);
  }
}
