import React, { FC, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import Input from "../../components/Input";
import LinkSpan from "../../components/Link";

import {
  globalUpdatePhone,
  globalValidationCode,
  globalValidationCodeResend,
} from "../../state/ducks/global/actions";
import { EnumSmsType } from "../../state/ducks/global/types";
import { IRootState } from "../../state/ducks/types";
import { ROUTE_HOME } from "../../config/routes";
import InputMask from "react-input-mask";

import "./SmsValidation.scss";

const SmsValidation: FC = () => {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");

  const [changePhone, setChangePhone] = useState<boolean>(false);
  const [newPhone, setNewPhone] = useState<string>("");

  const {
    sending,
    smsState,
    error,
    updatePhone: { loading, error: errorPhone, success },
  } = useSelector((state: IRootState) => state.global);

  const { player } = useSelector((state: IRootState) => state.global);

  const handleValidationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value.replace(/[^0-9]/gi, ""));
  };

  const resendSmsCode = () => {
    dispatch(globalValidationCodeResend(player?.id));
  };

  const submitSmsHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(globalValidationCode(code));
  };

  const submitPhoneUpdate = () => {
    dispatch(globalUpdatePhone(newPhone));
  };

  useEffect(() => {
    if (smsState === EnumSmsType.SendedSms) {
      setTimeout(() => {
        setRedirect(true);
      }, 2000);
    }
  }, [smsState]);

  useEffect(() => {
    if (success) {
      setChangePhone(false);
      setNewPhone("");
    }
  }, [success]);

  const smsMessage = () => {
    switch (smsState) {
      case EnumSmsType.SendedSms:
        return <div className="msg success">Cadastro finalizado com sucesso</div>;
      case EnumSmsType.ResendedSms:
        return <div className="msg success">O código de verificação foi enviado novamente</div>;
      case EnumSmsType.ErrorSms:
        return (
          <div className="msg error">
            {error ?? `Não foi possível enviar o código de segurança no momento`}
          </div>
        );
      default:
        return <Fragment></Fragment>;
    }
  };

  return (
    <Fragment>
      {redirect && <Redirect to={ROUTE_HOME} />}
      {smsMessage()}
      <form onSubmit={submitSmsHandler} className="form-validation">
        <h2>
          Para ativar o seu cadastro, informe o código de verificação que foi enviado por SMS para o
          número <span>{player?.phone}</span>.
        </h2>
        <div className="col col-25 col-last">
          <Input
            type="text"
            name="code"
            placeholder="Código de validação"
            maxLength={4}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValidationCode(e)}
            value={code || ""}
            required
          />
        </div>
        <div className="buttons">
          <Input
            type="submit"
            additionalClass="btn"
            value={sending ? "Validando..." : "Continuar"}
            disabled={sending}
          />
          <h2 className="mgt">Não recebeu seu código?</h2>
          <div onClick={() => resendSmsCode()}>
            <LinkSpan>{sending ? "Aguarde..." : "Reenviar código"}</LinkSpan>
          </div>
          <div onClick={() => setChangePhone(true)} className="alt-phone">
            <LinkSpan>{/*sending ? "Aguarde..." : "Reenviar código" */}Alterar número</LinkSpan>
          </div>
        </div>
      </form>
      {changePhone && (
        <form className="form-validation form-phoneupdate">
          <h2>Alterar número de seu telefone e reenviar código</h2>
          <div className="col col-25 col-last">
            <InputMask
              className="input"
              type="text"
              name="newPhone"
              mask="(99)99999-9999"
              maskPlaceholder="_"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPhone(e.target.value)}
              value={newPhone}
              placeholder="Novo telefone"
            />
          </div>
          <div onClick={() => submitPhoneUpdate()}>
            <LinkSpan>{loading ? "Aguarde..." : "Confirmar telefone"}</LinkSpan>
          </div>
        </form>
      )}
      {errorPhone && <div className="msg error">{errorPhone}</div>}
      {success && !changePhone && <div className="msg success">Telefone alterado com sucesso</div>}
    </Fragment>
  );
};

export default SmsValidation;
