import React, { FC, useEffect, useState } from "react";
import ApiClient from "../../services/ApiClient";
import parse from "html-react-parser";

const TermosCondicoes: FC = () => {
  const [termosCond, setTermosCond] = useState<string>("");

  useEffect(() => {
    const loadTermos = async () => {
      try {
        const termos = await ApiClient.get(`settings/terms`);
        setTermosCond(termos.data);
      } catch (error) {
        console.log(error);
      }
    };
    loadTermos();
  }, []);
  return (
    <div className="institucional">
      <div className="container">
        <h1>Termos e Condições</h1>
        {parse(termosCond)}
      </div>
    </div>
  );
};

export default TermosCondicoes;
