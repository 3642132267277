import React, { FC } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import {
  ROUTE_LOGIN,
  ROUTE_HOME,
  ROUTE_CADASTRO,
  ROUTE_COMPONENTS_LIBRARY,
  ROUTE_PERFIL,
  ROUTE_NOTIFICACOES,
  ROUTE_SAQUE,
  ROUTE_INDIQUE,
  ROUTE_INDIQUE_FINALIZAR,
  ROUTE_TROQUENIVEL,
  ROUTE_ESCOLHACARTELAS,
  ROUTE_CATEGORIA,
  ROUTE_INDIQUE_CADASTRO,
  ROUTE_QUEM_SOMOS,
  ROUTE_JOGO_RESPONSAVEL,
  ROUTE_TERMOS_CONDICOES,
  ROUTE_VIDEO_BINGO,
  ROUTE_DEPOSITO,
  ROUTE_PRAGMATIC,
} from "./config/routes";

import LoginRouter from "./routes/LoginRouter";
import Cadastro from "./containers/Cadastro";
import Perfil from "./containers/Perfil";
import Notificacoes from "./containers/Notificacoes";
import Saque from "./containers/Saque";
import Indique from "./containers/Indique";
import IndiqueFinalizar from "./containers/Indique/Form";
import IndiqueCadastro from "./containers/Indique/IndiqueCadastro";
import Troqueindique from "./containers/Troquenivel";
import Categoria from "./containers/Categoria/Categoria";
import EscolhaCartelas from "./containers/EscolhaCartelas/EscolhaCartelas";
import Main from "./routes/Main";
import Screen from "./components/Screen";
import ComponentsLibrary from "./routes/ComponentsLibrary";
import QuemSomos from "./containers/QuemSomos";
import TermosCondicoes from "./containers/TermosCondicoes/TermosCondicoes";
import JogoResponsavel from "./containers/JogoResponsavel";
import Videobingo from "./containers/Videobingo";
import Pragmatic from "./containers/Pragmatic";

import ScrollToTop from "./components/Screen/ScrollToTop";

import ModalDeposits from "./components/Modals/ModalDeposits";

import { IS_DEV } from "./config/constants";
import Deposito from "./components/Modals/ModalDeposits/Deposits";

const App: FC = () => {
  React.useEffect(() => {
    if (!IS_DEV) {
      document.onkeydown = function (e) {
        if (e.keyCode === 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "C".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
          return false;
        }
      };
      document.addEventListener("contextmenu", (event) => event.preventDefault());
    }
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <div className="wrapper">
          <ModalDeposits />
          {process.env.REACT_APP_MAINTENANCE === "ON" ? (
            <div className="maintenance">
              <div className="logo">
                <img src="/logo-bingotine.png" alt="Bingotine" />
              </div>
              <br />
              <h2>Estamos em manutenção.</h2>
              <br />
              <h3>Agradecemos a todos nossos clientes. Bingotine.</h3>
            </div>
          ) : (
            <Switch>
              <Screen withHeader withFooter exact path={ROUTE_HOME}>
                <Main />
              </Screen>
              <Screen withHeader withFooter path={ROUTE_CADASTRO}>
                <Cadastro />
              </Screen>
              <Screen withHeader withFooter path={ROUTE_LOGIN}>
                <LoginRouter />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_PERFIL}>
                <Perfil />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_NOTIFICACOES}>
                <Notificacoes />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_SAQUE}>
                <Saque />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_DEPOSITO}>
                <Deposito />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_ESCOLHACARTELAS}>
                <EscolhaCartelas />
              </Screen>
              <Screen withHeader withFooter validLogin withLobby path={ROUTE_INDIQUE}>
                <Indique />
              </Screen>
              <Screen withHeader withFooter withLobby path={ROUTE_INDIQUE_CADASTRO}>
                <IndiqueCadastro />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_TROQUENIVEL}>
                <Troqueindique />
              </Screen>
              <Screen withHeader withFooter withLobby validLogin path={ROUTE_INDIQUE_FINALIZAR}>
                <IndiqueFinalizar />
              </Screen>
              <Screen withHeader withFooter path={ROUTE_CATEGORIA}>
                <Categoria />
              </Screen>
              <Screen withHeader withFooter validLogin path={ROUTE_VIDEO_BINGO}>
                <Videobingo />
              </Screen>
              <Screen withHeader withFooter validLogin path={ROUTE_PRAGMATIC}>
                <Pragmatic />
              </Screen>
              <Screen withHeader withFooter withLobby path={ROUTE_QUEM_SOMOS}>
                <QuemSomos />
              </Screen>
              <Screen withHeader withFooter withLobby path={ROUTE_TERMOS_CONDICOES}>
                <TermosCondicoes />
              </Screen>
              <Screen withHeader withFooter withLobby path={ROUTE_JOGO_RESPONSAVEL}>
                <JogoResponsavel />
              </Screen>
              <Screen path={ROUTE_COMPONENTS_LIBRARY}>
                <ComponentsLibrary />
              </Screen>
            </Switch>
          )}
        </div>
      </ScrollToTop>
    </Router>
  );
};

export default App;
