import { IReduxAction } from "../../../global";
import {
  WITHDRAW,
  WITHDRAW_ERROR,
  WITHDRAW_FULLFILLED,
  WITHDRAW_RESET,
  IWithDrawction,
} from "./types";

export const postWithdraw = (
  amount: string,
  email: string,
  pix_key: string,
  pix_key_type: string,
  withdraw_type: string
): IWithDrawction => ({
  type: WITHDRAW,
  payload: {
    amount,
    email,
    pix_key,
    pix_key_type,
    withdraw_type,
  },
});

export const postWithdrawFullfilled = (): IReduxAction => ({
  type: WITHDRAW_FULLFILLED,
});

export const postWithdrawError = (message: string): IReduxAction => ({
  type: WITHDRAW_ERROR,
  payload: {
    message,
  },
});

export const resetWithdraw = (): IReduxAction => ({
  type: WITHDRAW_RESET,
});
