import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../state/ducks/types";
import CurrencyInput from "react-currency-input-field";

import { postWithdraw, resetWithdraw } from "../../state/ducks/withdraw/actions";
import { EnumWithDrawType } from "../../state/ducks/withdraw/types";

import { formatCurrency } from "../../utils/Functions";
import Input from "../../components/Input";
import Button from "../../components/Button";

const Form: FC = () => {
  const dispatch = useDispatch();

  const {
    player: { comission_balance, email },
  } = useSelector((state: IRootState) => state.global);

  const { error, loading, success } = useSelector((state: IRootState) => state.withdraw);

  const [pix, setPix] = useState<string>("");
  const [pixType, setPixType] = useState<string>("");
  const [valor, setValor] = useState<string>("");

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(postWithdraw(valor, email, pix, pixType, EnumWithDrawType.WITHDRAW_COMISSAO));
  };

  useEffect(() => {
    if (success) {
      setPix("");
      setPixType("");
      setValor("");
    }
  }, [success]);

  useEffect(() => {
    dispatch(resetWithdraw());
  }, [dispatch]);

  return (
    <div className="indique indique-form">
      <div className="container row">
        <div className="col-12 col-last col-m-25 col-m-last">
          <h1>Saque Indique e Ganhe</h1>
          {error && <div className="msg error">{error}</div>}
          {success && <div className="msg success">Pedido realizado com sucesso</div>}
          <div className="wrap-form">
            <form onSubmit={submitHandler}>
              <div className="row">
                <div className="col col-15 item">
                  <span>Valor total disponível para Saque:</span>
                </div>
                <div className="col col-10 col-last item">
                  <span>
                    <b>{formatCurrency(comission_balance)}</b>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col col-15 item">
                  <label htmlFor="valor">Informe o valor</label>
                </div>
                <div className="col col-10 col-last">
                  <CurrencyInput
                    id="valor"
                    name="valor"
                    allowDecimals={true}
                    decimalSeparator={","}
                    groupSeparator={"."}
                    onValueChange={(value) => setValor(value ?? "")}
                    value={valor}
                    decimalScale={2}
                    required
                    className="input"
                    style={{ textAlign: "right" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-15 item">
                  <label htmlFor="pix_key_type">Tipo da chave Pix</label>
                </div>
                <div className="col col-10 col-last">
                  <select
                    id="pix_key_type"
                    className="input"
                    required
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setPixType(e.target.value)
                    }
                  >
                    <option value="">Selecione a opção</option>
                    <option value="CPF/CNPJ">CPF/CNPJ</option>
                    <option value="E-MAIL">E-MAIL</option>
                    <option value="TELEFONE">TELEFONE</option>
                    <option value="CHAVE ALEATÓRIA">CHAVE ALEATÓRIA</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col col-15 item">
                  <label htmlFor="pix">Informe o Pix</label>
                </div>
                <div className="col col-10 col-last">
                  <Input
                    id="pix"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPix(e.target.value)}
                  />
                </div>
              </div>

              <Button text={loading ? "ENVIANDO DADOS..." : "SOLICITAR SAQUE"} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
