import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { IRootState } from "../../../state/ducks/types";
import { closeModalAction } from "../../../state/ducks/deposits/actions";

import ModalGlobal from "../ModalGlobal";

import "./ModalDeposits.scss";
import { EnumDepositsStates, EnumDepositsTab } from "../../../state/ducks/deposits/types";
import OrderSuccess from "./OrderSuccess";
//import OrderPayment from "./OrderPayment";
import Deposits from "./Deposits";
import OrderError from "./OrderError";

const ModalDeposits: React.FC = () => {
  const dispatch = useDispatch();
  const { modal, state, method } = useSelector((state: IRootState) => state.deposits);

  const checkoutState = React.useMemo(() => {
    if (method === EnumDepositsTab.CARTAO) {
      switch (state) {
        case EnumDepositsStates.PaymentForm:
          //return <OrderPayment />;
          return <></>;
        case EnumDepositsStates.AfterOrder:
          return <OrderSuccess />;
      }
    } else {
      switch (state) {
        case EnumDepositsStates.AfterOrder:
          return <OrderSuccess />;
        case EnumDepositsStates.ErrorOrder:
          return <OrderError />;
        case EnumDepositsStates.Stable:
          return <Deposits />;
      }
    }
  }, [method, state]);

  return (
    <ModalGlobal
      id="modal-checkout"
      isOpen={modal.isOpen}
      onClickOverlay={() => dispatch(closeModalAction())}
    >
      {checkoutState}
    </ModalGlobal>
  );
};

export default ModalDeposits;
