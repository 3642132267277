import React, { FC } from "react";
import { IAttributes } from "../../global";

export interface INivelItemProps extends IAttributes {
  title: string;
  imagem: string;
  coin: number;
  value: number;
}

const NivelItem: FC<INivelItemProps> = ({ title, imagem, coin, value, withdrawLock }) => {
  return (
    <div className="item column aic jcc">
      <img src={imagem} alt={title} />
      <span className="name">{title}</span>
      <span className="value row aic">
        <img width="20" src="xp_icon.png" alt="Cifrão" /> {coin}
      </span>
      <span className="value row aic">
        <img width="20" src="money_icon.png" alt="Coin" />
        {value}
      </span>
    </div>
  );
};

export default NivelItem;
