export const PRODUCTS = "PRODUCTS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const PRODUCTS_FULLFILLED = "PRODUCTS_FULLFILLED";

export interface IProductsState {
  loading: boolean;
  errorMessage: string;
  products: IProducts[];
}

export interface IProducts {
  id: number;
  description: string;
  amount: number;
  productItems: IProductItems[];
}

export interface IProductItems {
  id: number;
  description: string;
  amount: number;
  type: string;
}
