import React, { FC } from "react";
import { IAttributes } from "../../global";

export interface IWinningCardProps extends IAttributes {
  bigText?: string;
  username?: string;
  price?: string;
  image: string;
}

const WinningCard: FC<IWinningCardProps> = ({ bigText, username, price, image }) => {
  return (
    <div className="item row align-items-center">
      <img src={image} alt="Imagem winning card" />
      <>
        {bigText ? (
          <span className="big">{bigText}</span>
        ) : (
          <div>
            <span className="username">{username}</span>
            <span className="price">{price}</span>
          </div>
        )}
      </>
    </div>
  );
};

export default WinningCard;
