import React, { CSSProperties, FC } from "react";
import { IAttributes } from "../../global";
import { Link } from "react-router-dom";

import "./SmartLink.scss";

export interface ISmartLinkProps extends IAttributes {
  text: string;
  smallText?: string;
  imagem: string;
  link?: string;
  styleImage?: CSSProperties;
}

const SmartLink: FC<ISmartLinkProps> = ({ text, smallText, imagem, link, styleImage }) => {
  return (
    <div className="item">
      <Link to={`/${link}`} className="column align-items-center justify-content-between">
        <img style={styleImage} src={imagem} alt={text} />
        <span>
          {text} <br />
          {smallText && <small>{smallText}</small>}
        </span>
      </Link>
    </div>
  );
};

export default SmartLink;
