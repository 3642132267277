import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import moment from "moment";

import Input from "../../components/Input";
import InputMask from "react-input-mask";
import { globalEdit, globalGetAvatars, globalReset } from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";
import "./Perfil.scss";

const Perfil: FC = () => {
  const dispatch = useDispatch();

  const {
    player: { nickname, email, avatar, gender, birth_date },
    error,
    sending,
    avatars,
    success,
  } = useSelector((state: IRootState) => state.global);

  useEffect(() => {
    dispatch(globalGetAvatars());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setChangeNickname(false);
      setChangeEmail(false);
      setChangeGender(false);
      setChangeBirthDate(false);
      setChangePassword(false);

      setNicknamevalue("");
      setEmailvalue("");
      setGendervalue("");
      setPasswordvalue("");
      setPasswordconfirmvalue("");
      setBirthdatevalue("");

      setSuccessLocal(true);
    }
  }, [success]);

  useEffect(() => {
    if (avatar?.id !== undefined) {
      setAvatarvalue(Number(avatar?.id));
    }
  }, [avatar]);

  useEffect(() => {
    dispatch(globalReset());
    setSuccessLocal(false);
  }, [dispatch]);

  const [changeNickname, setChangeNickname] = useState<boolean>(false);
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [changeBirthDate, setChangeBirthDate] = useState<boolean>(false);
  const [changeGender, setChangeGender] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);

  const [nicknamevalue, setNicknamevalue] = useState<string>(nickname);
  const [emailvalue, setEmailvalue] = useState<string>(email);
  const [currentpasswordvalue, setCurrentpasswordvalue] = useState<string>("");
  const [passwordvalue, setPasswordvalue] = useState<string>("");
  const [passwordconfirmvalue, setPasswordconfirmvalue] = useState<string>("");
  const [birthdatevalue, setBirthdatevalue] = useState<string>();
  const [gendervalue, setGendervalue] = useState<string>(gender);
  const [successLocal, setSuccessLocal] = useState<boolean>(false);

  const [avatarvalue, setAvatarvalue] = useState<number>(0);

  const handleChangeNickNamevalue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNicknamevalue(e.target.value.replace(/[^a-z0-9]/gi, ""));
  };

  const handlerForm = (e: React.FormEvent) => {
    e.preventDefault();

    handlerAlteracoes();
  };

  const handlerAlteracoes = () => {
    const editList: any = {};
    if (changeNickname) {
      editList.nickname = nicknamevalue;
    }
    if (changeEmail) {
      editList.email = emailvalue;
    }
    if (changePassword) {
      editList.password = passwordvalue;
      editList.password_confirmation = passwordconfirmvalue;
      editList.current_password = currentpasswordvalue;
    }
    if (changeBirthDate) {
      editList.birth_date = birthdatevalue;
    }
    if (changeGender) {
      editList.gender = gendervalue;
    }
    if (avatarvalue !== undefined) {
      editList.avatar_id = avatarvalue;
    }

    setSuccessLocal(false);

    dispatch(globalEdit(editList));
  };

  return (
    <div className="perfil">
      <div className="container">
        <div className="row flex-m-column">
          <div className="col col-13 col-m-25 col-m-last">
            <div className="wrap-info">
              <h1>Meu Perfil</h1>

              {error && <span className="msg error">{error}</span>}
              {successLocal && <span className="msg success">Dados atualizados com sucesso!</span>}
              <form onSubmit={handlerForm}>
                <div className="item row aic">
                  <div className="col col-margin-0 col-5">
                    <span className="opt-label">Apelido</span>
                  </div>
                  <div className="col col-margin-0 col-15">
                    {!changeNickname ? (
                      <span className="opt-value">{nickname}</span>
                    ) : (
                      <Input
                        name="nickname"
                        value={nicknamevalue}
                        placeholder="Apelido"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeNickNamevalue(e)
                        }
                        required={changeNickname}
                      />
                    )}
                  </div>
                  <div className="col col-margin-0 col-5 col-last">
                    <span className="link" onClick={() => setChangeNickname(!changeNickname)}>
                      Atualizar <span>apelido</span>
                    </span>
                  </div>
                </div>
                <div className="item aic row">
                  <div className="col col-margin-0 col-5">
                    <span className="opt-label">E-mail</span>
                  </div>
                  <div className="col col-margin-0 col-15">
                    {!changeEmail ? (
                      <span className="opt-value">{email}</span>
                    ) : (
                      <Input
                        name="email"
                        type="email"
                        placeholder="E-mail"
                        value={emailvalue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEmailvalue(e.target.value)
                        }
                        required={changeEmail}
                      />
                    )}
                  </div>
                  <div className="col col-margin-0 col-5 col-last">
                    <span className="link" onClick={() => setChangeEmail(!changeEmail)}>
                      Atualizar <span>e-mail</span>
                    </span>
                  </div>
                </div>
                <div className="item row">
                  <div className="col col-margin-0 col-5">
                    <span className="opt-label">Senha</span>
                  </div>
                  <div className="col col-margin-0 col-15">
                    {changePassword && (
                      <div>
                        <div className="col col-margin-0 col-25 col-last">
                          <Input
                            type="password"
                            name="current_password"
                            value={currentpasswordvalue}
                            minLength={4}
                            maxLength={10}
                            required={changePassword}
                            placeholder="Senha Atual"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setCurrentpasswordvalue(e.target.value)
                            }
                          />
                        </div>
                        <div className="col col-margin-0 col-25 col-last">
                          <Input
                            type="password"
                            name="password"
                            value={passwordvalue}
                            minLength={4}
                            maxLength={10}
                            required={changePassword}
                            placeholder="Nova Senha"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setPasswordvalue(e.target.value)
                            }
                          />
                        </div>
                        <div className="col col-margin-0 col-25 col-last">
                          <Input
                            type="password"
                            name="password_confirma"
                            value={passwordconfirmvalue}
                            minLength={4}
                            maxLength={10}
                            required={changePassword}
                            placeholder="Confirmar Nova Senha"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setPasswordconfirmvalue(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col col-margin-0 col-5 col-last">
                    <span className="link" onClick={() => setChangePassword(!changePassword)}>
                      Atualizar <span>senha</span>
                    </span>
                  </div>
                </div>
                <div className="item aic row">
                  <div className="col col-margin-0 col-6">
                    <span className="opt-label">Data de nascimento</span>
                  </div>
                  <div className="col col-margin-0 col-14">
                    {!changeBirthDate ? (
                      <span className="opt-value">
                        {moment(birth_date).format("DD/MM/YYYY").toString()}
                      </span>
                    ) : (
                      <InputMask
                        name="birth_date"
                        mask="99/99/9999"
                        type="text"
                        className="input"
                        placeholder="Data de nascimento"
                        value={birthdatevalue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBirthdatevalue(e.target.value)
                        }
                        required={changeBirthDate}
                      />
                    )}
                  </div>
                  <div className="col col-margin-0 col-5 col-last"></div>
                </div>
                <div className="item aic row">
                  <div className="col col-margin-0 col-6">
                    <span className="opt-label">Sexo</span>
                  </div>
                  <div className="col col-margin-0 col-14">
                    {!changeGender ? (
                      <span className="opt-value">
                        {gender === "M"
                          ? "Masculino"
                          : gender === "F"
                          ? "Feminino"
                          : "Prefiro não informar"}
                      </span>
                    ) : (
                      <select
                        className="input"
                        id="gender"
                        name="gender"
                        required
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setGendervalue(e.target.value)
                        }
                        value={gendervalue}
                      >
                        <option value="">Selecione uma opcão</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                        <option value="N">Prefiro não informar</option>
                      </select>
                    )}
                  </div>
                  <div className="col col-margin-0 col-5 col-last"></div>
                </div>
                {(changeNickname ||
                  changeEmail ||
                  changeBirthDate ||
                  changeGender ||
                  changePassword) && (
                  <Input type="submit" value={sending ? "SALVANDO..." : "SALVAR ALTERAÇÕES"} />
                )}
              </form>
            </div>
          </div>
          <div className="col col-12 col-last col-m-25 col-m-last">
            <div className="wrap-avatar">
              <h2>Avatar</h2>
              <div className="grid">
                {avatars &&
                  avatars.map((item, key) => (
                    <img
                      className={avatarvalue === item.id ? "active" : ""}
                      src={item.image_url}
                      alt={item.description}
                      onClick={() => setAvatarvalue(item.id)}
                      key={key}
                    />
                  ))}
              </div>
              <Button
                text={sending ? "SALVANDO..." : "SALVAR AVATAR"}
                style={{ width: "100%", height: "40px", backgroundColor: "#7ccb00" }}
                fontStyle={{ color: "#FFF", fontSize: "24px", fontWeight: 600 }}
                onClick={() => handlerAlteracoes()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
