import ApiClient from "../../../services/ApiClient";

class CheckoutService {
  static getPacks() {
    return ApiClient.get("/produtos");
  }

  static getCupons() {
    return ApiClient.get("/cupons");
  }

  static sendOrder({ checkout }: any, method: string) {
    return ApiClient.post(`/pedidos/${method}`, checkout);
  }

  static rescueCupom(cupom: string) {
    return ApiClient.post(`/pedidos/cupons`, { cupom });
  }
}

export default CheckoutService;
