import React, { FC, useRef } from "react";
import { useParams } from "react-router";

import "./Categoria.scss";

import ListaCategorias from "./ListaCategorias";
import Banner from "../../components/Banner";
import Lobby from "../Lobby";
//import { useSelector } from "react-redux";
//import { IRootState } from "../../state/ducks/types";
import WhoWinning from "../../components/WhoWinning";

const Categoria: FC = () => {
  const anchor = useRef<HTMLDivElement>(null);
  const { slug }: { slug: string } = useParams();

  /*
  const { isGamesLoaded } = useSelector((state: IRootState) => state.games);
  useLayoutEffect(() => {
    if (anchor.current && isGamesLoaded) {
      anchor.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isGamesLoaded, slug]);
  */

  return (
    <div className="categoria">
      <div className="container">
        <Banner />
        <Lobby />
        <WhoWinning />
        <div ref={anchor}></div>
        <ListaCategorias limit={false} value={0} slugCategoria={slug} />
      </div>
    </div>
  );
};

export default Categoria;
