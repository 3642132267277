export const DEPOSITS = "DEPOSITS";
export const DEPOSITS_ERROR = "DEPOSITS_ERROR";
export const DEPOSITS_FULLFILLED = "DEPOSITS_FULLFILLED";

export const OPEN_MODAL_DEPOSITS = "OPEN_MODAL_DEPOSITS";
export const CLOSE_MODAL_DEPOSITS = "CLOSE_MODAL_DEPOSITS";

export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

export const EMPTY_FLAGS = "EMPTY_FLAGS";

export const TRY_AGAIN_PAYMENT = "TRY_AGAIN_PAYMENT";

export interface IDepositsState {
  loading: boolean;
  error: string;
  deposito: IDeposit;
  modal: {
    isOpen: boolean;
  };
  method: string;
  state: EnumDepositsStates;
  card: IDepositCard | undefined;
  productId: number | undefined;
}

export interface IDeposit {
  id: number;
  status: string;
  payment_type: string;
  amount: number;
  player_id: number;
  depositMpRespone: IDepositResponse;
}

export interface IDepositResponse {
  id: number;
  status: string;
  payment_id: string;
  qr_code: string;
  qr_code_string: string;
  qr_code_base64: string;
  deposit_id: number;
}

export enum EnumDepositsTab {
  PIX = "PIX",
  CARTAO = "CARD",
}

export enum EnumDepositsStates {
  LoadingOrder,
  ErrorOrder,
  Stable,
  AfterOrder,
  PaymentForm,
}

export interface IDepositCard {
  cvc?: string;
  expiry?: string;
  name?: string;
  number?: string;
  instalments: number;
  issuer?: string;
  cpf?: string;
  token?: string;
}
