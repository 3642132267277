import React, { FC } from "react";

const QuemSomos: FC = () => {
  return (
    <div className="institucional">
      <div className="container">
        <h1>Quem Somos?</h1>
        <div className="text">
          <p className="center">Seja Bem-Vindo ao Bingotine!</p>
          <p>
            Bingotine é um site de entretenimento online onde você pode desfrutar dos melhores jogos
            de Cassino e de Bingo, com total segurança no seu computador ou smartphone.
          </p>
          <p>Bingotine é uma sociedade de responsabilidade limitada.</p>
          <p>Venha se divertir e testar sua sorte! Faça já o seu cadastro!</p>
          <p>Afinal de contas que não joga, não ganha e quem não arrisca não petisca!</p>
          <p>Aqui no Bingotine sua diversão é garantida!</p>
        </div>
      </div>
    </div>
  );
};

export default QuemSomos;
