export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REPRESENTANTES = "/representantes";
export const ROUTE_CADASTRO = "/cadastro";
export const ROUTE_PERFIL = "/perfil";
export const ROUTE_NOTIFICACOES = "/notificacoes";
export const ROUTE_SAQUE = "/saque";
export const ROUTE_DEPOSITO = "/deposito";
export const ROUTE_INDIQUE = "/indique-ganhe";
export const ROUTE_INDIQUE_FINALIZAR = "/saque-indique-ganhe";
export const ROUTE_INDIQUE_CADASTRO = "/ind/:slug";
export const ROUTE_TROQUENIVEL = "/troquenivel";
export const ROUTE_ESCOLHACARTELAS = "/escolha-cartelas";
export const ROUTE_FASTBINGO = "/fastbingo/:slug";
export const ROUTE_CATEGORIA = "/categoria/:slug";
export const ROUTE_QUEM_SOMOS = "/quem-somos";
export const ROUTE_JOGO_RESPONSAVEL = "/jogo-responsavel";
export const ROUTE_INDIQUE_GANHE = "/indique-ganhe";
export const ROUTE_TERMOS_CONDICOES = "/termos-condicoes";
export const ROUTE_VIDEO_BINGO = "/jogos-de-bingo/:slug";
export const ROUTE_COMPONENTS_LIBRARY = "/components";
export const ROUTE_PRAGMATIC = "/pragmatic/:id";
