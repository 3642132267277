import classNames from "classnames";
import React from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { ROUTE_NOTIFICACOES } from "../../config/routes";
import { INotificationsData } from "../../state/ducks/global/types";

import "./Notifications.scss";

export interface INotificationsProps {
  notifications?: INotificationsData[];
  closeToggle: () => void;
  handlerPopupVideo: (video: string) => void;
  videoPopup: string;
  showPopupVideo: boolean;
}

const Notifications: React.FC<INotificationsProps> = ({
  closeToggle,
  notifications,
  handlerPopupVideo,
  videoPopup,
  showPopupVideo,
}) => {
  const notificationsRef = useDetectClickOutside({ onTriggered: closeToggle });

  return (
    <>
      {showPopupVideo && (
        <div className="showPopupVideo">
          <span className="close" onClick={() => handlerPopupVideo("")}>
            X
          </span>

          <video src={videoPopup} controls></video>
        </div>
      )}
      <div className={classNames("float-notmenu column")} ref={notificationsRef}>
        <div className="header">
          <h3>NOTIFICAÇÕES</h3>
          <span className="close" onClick={closeToggle}>
            X
          </span>
        </div>
        <hr />

        {notifications?.map((item) => (
          <div className="fm-item" key={item.id}>
            <div
              style={{ backgroundImage: `url(${item?.image_url ?? "/bingotine.png"})` }}
              className="image"
            ></div>
            <p>
              {parse(item.message)}
              {item?.notification?.code === "WELCOME" && (
                <div className="links">
                  <div
                    className="link"
                    onClick={() =>
                      handlerPopupVideo("https://assets2.bingotine.com/media/videos/video1.mp4")
                    }
                  >
                    🍀 Bingotine
                  </div>
                  <div
                    className="link"
                    onClick={() =>
                      handlerPopupVideo("https://assets2.bingotine.com/media/videos/video2.mp4")
                    }
                  >
                    🍀 Como ganhar bônus
                  </div>
                  <div
                    className="link"
                    onClick={() =>
                      handlerPopupVideo("https://assets2.bingotine.com/media/videos/video3.mp4")
                    }
                  >
                    🍀 Depositar
                  </div>
                  {/* <div
                    className="link"
                    onClick={() =>
                      handlerPopupVideo("https://assets2.bingotine.com/media/videos/video4.mp4")
                    }
                  >
                    🍀 Sacar
                  </div> */}
                  <div
                    className="link"
                    onClick={() =>
                      handlerPopupVideo("https://assets2.bingotine.com/media/videos/video5.mp4")
                    }
                  >
                    🍀 Faça saques sem ganhar
                  </div>
                  <p>No Bingotine sua diversão é garantida e VOCÊ GANHA DE VERDADE!</p>
                </div>
              )}
            </p>
          </div>
        ))}
        <Link onClick={closeToggle} to={ROUTE_NOTIFICACOES}>
          VER MAIS
        </Link>
      </div>
    </>
  );
};

export default Notifications;
