import { takeLatest, all, put, call } from "redux-saga/effects";
import { DEPOSITS, EnumDepositsTab } from "./types";

import { postDeposit } from "./api";

import { getDepositError, getDepositFullfilled, openModalAction } from "./actions";
import { rejectApi } from "../../../utils/Functions";

export function* depositsSaga() {
  yield all([yield takeLatest(DEPOSITS, deposit)]);
}

function* deposit({ payload }: any) {
  try {
    const { payment_type, card_brand } = payload;

    if (payment_type === EnumDepositsTab.CARTAO) {
      let cartao: string | undefined;
      switch (card_brand) {
        case "mastercard":
          cartao = "master";
          break;
        default:
          cartao = card_brand;
      }

      payload.card_brand = cartao;
    }

    //console.log(payload);

    const { data } = yield call(postDeposit, payload);
    yield put(getDepositFullfilled(data));
    yield put(openModalAction());
  } catch (err) {
    const error = rejectApi(err);
    console.log(error);
    yield put(getDepositError(error));
  }
}
