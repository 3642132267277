import React, { FC, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import Input from "../../components/Input";

import { globalSignUp, globalReset } from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";
import InputMask from "react-input-mask";

import {
  CADASTRO_INDICACAO,
  EnumControlSmsFormType,
  EnumSmsType,
} from "../../state/ducks/global/types";

import { ROUTE_HOME, ROUTE_TERMOS_CONDICOES } from "../../config/routes";

import SmsValidation from "./SmsValidation";

import "./Cadastro.scss";

const Cadastro: FC = () => {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState<boolean>(false);

  const [nickname, setNickname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [accept, setAccept] = useState<boolean>(false);
  const [nicknameIndication, setNicknameIndication] = useState<string | null>("");

  const { sending, error, loggedIn } = useSelector((state: IRootState) => state.global);

  const { savedPlayer, smsControlForm, smsState } = useSelector(
    (state: IRootState) => state.global
  );

  const handleChangeNickName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value.replace(/[^a-z0-9]/gi, ""));
  };

  useEffect(() => {
    setNicknameIndication(localStorage.getItem(CADASTRO_INDICACAO));
  }, []);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      globalSignUp(
        fullname,
        nickname,
        email,
        password,
        confirmPassword,
        phone,
        birthDate,
        gender,
        accept ? 1 : 0,
        nicknameIndication
      )
    );
  };

  const clearForm = () => {
    setFullname("");
    setNickname("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setPhone("");
    setBirthDate("");
    setGender("");
    setNicknameIndication("");
    setTimeout(() => {
      setRedirect(true);
    }, 3000);
  };

  useEffect(() => {
    if (savedPlayer) {
      localStorage.removeItem(CADASTRO_INDICACAO);
      clearForm();
    }
  }, [savedPlayer]);

  useEffect(() => {
    dispatch(globalReset());
  }, [dispatch]);

  useEffect(() => {
    if (
      smsControlForm === EnumControlSmsFormType.SmsForm ||
      error ||
      smsState !== EnumSmsType.LoadingSms
    ) {
      window.scrollTo(0, 0);
    }
  }, [error, smsControlForm, smsState]);

  return (
    <div className="cadastro">
      {(redirect || loggedIn) && <Redirect to={ROUTE_HOME} />}
      <div className="container row flex-m-column">
        <div className="col col-13 col-m-25 col-m-last">
          <div className="wrap-form">
            {error && !savedPlayer && <div className="msg error">{error}</div>}

            {smsControlForm === EnumControlSmsFormType.SmsForm && <SmsValidation />}

            {smsControlForm === EnumControlSmsFormType.RegularForm && (
              <form onSubmit={submitHandler}>
                <div className="col col-25 col-last">
                  <Input
                    type="text"
                    name="full_name"
                    placeholder="Nome"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFullname(e.target.value)
                    }
                    value={fullname}
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <Input
                    type="text"
                    name="nickname"
                    placeholder="Apelido no Jogo"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeNickName(e)}
                    value={nickname}
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <Input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <Input
                    type="password"
                    name="password"
                    minLength={6}
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    placeholder="Senha"
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <Input
                    type="password"
                    name="confirm_password"
                    minLength={6}
                    value={confirmPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setConfirmPassword(e.target.value)
                    }
                    placeholder="Confirmar Senha"
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <InputMask
                    className="input"
                    mask="(99)99999-9999"
                    type="text"
                    name="phone"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                    placeholder="Número Celular"
                    value={phone}
                    maskPlaceholder="_"
                    autoComplete=""
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <InputMask
                    className="input"
                    mask="99/99/9999"
                    type="text"
                    name="birth_date"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setBirthDate(e.target.value)
                    }
                    placeholder="Data de nascimento"
                    value={birthDate}
                    maskPlaceholder="_"
                    autoComplete=""
                    required
                  />
                </div>
                <div className="col col-25 col-last">
                  <select
                    className="input"
                    id="gender"
                    name="gender"
                    required
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setGender(e.target.value)
                    }
                  >
                    <option value="">Selecione uma opcão</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="N">Prefiro não informar</option>
                  </select>
                </div>
                <div className="col col-25 col-last">
                  <div className="row row-label align-items-start">
                    <Input
                      inputStyle={{
                        width: "27px",
                        height: "27px",
                        border: "1px solid #7b7b7b",
                        marginRight: "15px",
                      }}
                      name="accept"
                      type="checkbox"
                      value={1}
                      id="aceite"
                      checked={accept}
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAccept(e.target.checked)
                      }
                    />
                    <label htmlFor="aceite">
                      Eu tenho 18 anos de idade ou mais e li e aceitei seus{" "}
                      <span>
                        <Link target="_blank" to={ROUTE_TERMOS_CONDICOES}>
                          Termos e Condições
                        </Link>
                      </span>
                      .
                    </label>
                  </div>
                </div>
                <div className="buttons">
                  <Input
                    type="submit"
                    additionalClass="btn"
                    value={sending ? "SALVANDO..." : "CRIAR CONTA"}
                    disabled={sending}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="col col-12 col-m-25 col-last col-m-last">
          <div className="image-block row align-items-center justify-content-center">
            <span className="big-text">
              🍀 Crie sua conta, curta <br /> e siga nossa página no <br /> Facebook e Instagram
              <br />e{" "}
              <span className="green">
                ganhe bônus de <br /> boas vindas*
              </span>{" "}
              para se
              <br /> divertir 🍀
            </span>
          </div>
          <div className="text">
            <p>
              <b>Bem-vindo ao Bingotine. A sua casa de jogos online.</b>
            </p>
            <p>Aqui sua diversão é garantida!</p>
            <br />
            <p>Curta e siga nossa página no Facebook e Instagram e ganhe </p>
            <p>
              <span className="green">bônus de boas vindas.*</span>
            </p>
            <p>
              <a href="https://www.facebook.com/bingotine" rel="noreferrer" target="_blank">
                www.facebook.com/bingotine
              </a>
            </p>
            <p>
              <a href="https://www.instagram.com/bingotine" rel="noreferrer" target="_blank">
                www.instagram.com/bingotine
              </a>
            </p>
            <br />
            <p>
              Depois que curtir e seguir basta enviar o print para nosso atendimento que adicionamos
              bônus em sua conta.
            </p>
            <br />
            <span className="obs">*Tcc são aplicáveis</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cadastro;
