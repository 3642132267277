import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";

import { ROUTE_CADASTRO } from "../../config/routes";

import { CADASTRO_INDICACAO } from "../../state/ducks/global/types";

const IndiqueCadastro: FC = () => {
  const [redirCadastro, setRedirCadastro] = useState<boolean>(false);
  const { slug }: { slug: string } = useParams();

  useEffect(() => {
    localStorage.setItem(CADASTRO_INDICACAO, slug);
    setRedirCadastro(true);
  }, [slug]);

  return (
    <div className="indique-cadastro">{redirCadastro && <Redirect to={ROUTE_CADASTRO} />}</div>
  );
};

export default IndiqueCadastro;
