import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Link } from "react-router-dom";

import { formatCurrency } from "../../utils/Functions";

import "react-confirm-alert/src/react-confirm-alert.css";

import LinkSpan from "../../components/Link";
import Button from "../../components/Button";
import Input from "../../components/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import "./Indique.scss";
import { IRootState } from "../../state/ducks/types";
import { ROUTE_INDIQUE_FINALIZAR } from "../../config/routes";
import {
  //globalExchange,
  globalGetPlayerIndications,
  globalReset,
} from "../../state/ducks/global/actions";
//import { confirmAlert } from "react-confirm-alert";
//import { EnumExchangeType } from "../../state/ducks/global/types";

const Indique: FC = () => {
  const dispatch = useDispatch();
  const {
    exchangeSuccess,
    player: { nickname, accounts },
    error,
    loggedIn,
    playerIndications,
  } = useSelector((state: IRootState) => state.global);

  const findAccountValue = (type: string): number => {
    return accounts.find((account) => account.accountType.code === type)?.balance ?? 0;
  };

  const saldo = findAccountValue("IND");

  const urlShare = `https://bingotine.com/ind/${nickname}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [error, exchangeSuccess]);

  /*
  const handleTrade = () => {
    const params = {
      type: EnumExchangeType.COMISSION,
    };
    dispatch(globalExchange(params));
  };
  */

  const handleFilter = () => {
    dispatch(globalGetPlayerIndications({ dateFrom: data.dateFrom, dateTo: data.dateTo }));
  };

  const handleFilterClean = () => {
    dispatch(globalGetPlayerIndications());
    setData({
      dateFrom: undefined,
      dateTo: undefined,
    });
  };

  useEffect(() => {
    dispatch(globalReset());
  }, [dispatch]);

  /*
  const confirmDialog = () => {
    confirmAlert({
      title: "Atenção",
      message: "Deseja transferir para a conta?",
      buttons: [
        {
          label: "Sim",
          onClick: () => handleTrade(),
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };
  */

  useEffect(() => {
    dispatch(globalGetPlayerIndications());
  }, [dispatch]);

  const [data, setData] = useState({
    dateFrom: undefined,
    dateTo: undefined,
  });

  const handleInputFilterChange = useCallback(
    (value, field: any) => {
      setData({
        ...data,
        [field]: value,
      });
    },
    [data]
  );

  return (
    <div className="indique">
      <div className="container row">
        <div className="col">
          <h1>
            Indique e ganhe <br />
            <span>Programa de Afiliados</span>
          </h1>
          {error && <div className="msg error">{error}</div>}
          {exchangeSuccess && <div className="msg success">Transação executada com sucesso</div>}
          {loggedIn && (
            <>
              <div className="text">
                <span>Copie seu código.</span>
              </div>
              <div className="wrap-link row justify-content-between aic">
                <Input type="text" value={urlShare} readOnly />
                <div className="row">
                  <CopyToClipboard text={urlShare}>
                    <img src="copiar.jpg" alt="CRopiar" />
                  </CopyToClipboard>
                  <a
                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                      urlShare
                    )}%0a💥Você recebeu uma indicação para se cadastrar no Bingotine.%0aVem se divertir e testar sua sorte 🍀%0aSe cadastre através do meu link e participe do jogo que mais cresce no Brasil!%0aTe espero lá e boa sorte 🍀`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkSpan>
                      <img src="compartilhar.jpg" alt="Compartilhar" />
                    </LinkSpan>
                  </a>
                </div>
              </div>
            </>
          )}

          <div className="wrap-image d-none d-m-block">
            <img src="indique-ganhe.jpg" alt="Indicou ganhou" />
          </div>

          {loggedIn && (
            <div className="btns">
              <p className="saldo">Saldo {formatCurrency(saldo, "real")}</p>
              <div className="flex-m-column">
                <Link to={ROUTE_INDIQUE_FINALIZAR}>
                  <Button text="SACAR SALDO" />
                </Link>
                {/*
                <div className="bigButton orange">
                  <Button text="TRANSFERIR PARA CONTA" onClick={confirmDialog} />
                </div>
                */}
              </div>
            </div>
          )}
        </div>
        <div className="wrap-image d-block d-m-none">
          <img src="indique-ganhe.jpg" alt="Indicou ganhou" />
        </div>
      </div>
      {loggedIn && (
        <div className="container gestao">
          <div className="row">
            <div className="col">
              <div className="btns">
                <div className="row aic flex-m-column">
                  <div className="box-count">
                    <div className="button green info">USUÁRIOS INDICADOS</div>
                    <div className="count">{playerIndications?.totalIndications ?? 0}</div>
                  </div>
                  <div className="box-count">
                    <div className="button orange info">USUÁRIOS ATIVOS</div>
                    <div className="count">{playerIndications?.totalActiveIndications ?? 0}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="filter">
              <div className="col">
                <DatePicker
                  selected={data.dateFrom}
                  onChange={(date) => handleInputFilterChange(date, "dateFrom")}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data inicial"
                />
                <DatePicker
                  selected={data.dateTo}
                  onChange={(date) => handleInputFilterChange(date, "dateTo")}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data final"
                />
                <Button text="Filtrar" onClick={handleFilter} />
                <Button
                  text="Limpar"
                  buttonStyle={{ backgroundColor: "#ff4200" }}
                  onClick={handleFilterClean}
                />
              </div>
            </div>
          </div>
          <div className="row list">
            <div className="divTable">
              <div className="divTableHeading">
                {playerIndications?.loading === false && (
                  <div className="divTableRow">
                    <div className="divTableCell">usuário</div>
                    <div className="divTableCell">e-mail</div>
                    <div className="divTableCell">crédito</div>
                    <div className="divTableCell">cadastro</div>
                    <div className="divTableCell">ativo</div>
                  </div>
                )}
              </div>
              <div className="divTableBody"></div>
              <div className="divTableBody">
                {playerIndications?.players?.map((indication) => (
                  <div className="divTableRow" key={indication.id}>
                    <div className="divTableCell">{indication.nickname}</div>
                    <div className="divTableCell">{indication.email}</div>
                    <div className="divTableCell">{formatCurrency(indication.amount)}</div>
                    <div className="divTableCell">{indication.createdAt}</div>
                    <div className="divTableCell">{indication.active ? "Sim" : "Não"}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="response">
              {playerIndications?.players?.length === 0 && !playerIndications?.loading && (
                <p className="message">Nenhuma indicação encontrada</p>
              )}
              {playerIndications?.error && (
                <p className="message error">{playerIndications?.error}</p>
              )}
              {playerIndications?.loading && <Spinner />}
            </div>
          </div>
        </div>
      )}
      <div className="container row">
        <div className="col">
          <div className="text">
            <span>Já pensou em se divertir e ainda poder ganhar dinheiro?</span>
            <span>
              Com nosso programa indique e ganhe aqui do Bingotine você pode se divertir e ganhar
              dinheiro de verdade!
            </span>
            <span>Para participar é muito fácil:</span>

            <div className="row aic">
              <img src="/ind-passo-1.png" alt="Passo um" className="img-step" />
              <span>
                Depois de estar cadastrado no jogo, clique no botão Menu, depois clique em indique e
                ganhe.
              </span>
            </div>

            <br />
            <div className="row aic">
              <img src="/ind-passo-2.png" alt="Passo dois" className="img-step" />
              <span>Copie seu código.</span>
            </div>

            <br />
            <div className="row aic">
              <img src="/ind-passo-3.png" alt="Passo três" className="img-step" />
              <span>
                Agora é só divulgar seu link em suas redes sociais ou seus amigos e pedir para que
                se cadastrem atraves de seu link!
              </span>
            </div>
            <br />
            <span>
              <b>Quanto mais indicar, mais você ganha!!!!</b>
            </span>
          </div>

          <div className="text">
            <img
              src="/ind-dolar.png"
              alt="Quanto mais indicar, mais você ganha"
              className="img-dolar"
            />
            <span>
              <b>Nível 1:</b> <br />
              De 01 até 20 novos jogadores – 1,5% sobre todos os depósitos efetuados.
            </span>
            <span></span>
            <span>
              <b>Nível 2:</b> <br />
              De 21 até 60 novos jogadores - 2% sobre todos os depósitos efetuados.
            </span>
            <span>
              <b>Nível 3:</b>
              <br />
              De 61 até 120 novos usuários – 3% sobre todos os depósitos efetuados.
            </span>
            <span>
              <b>Nível 4:</b>
              <br />
              Acima de 121 novos usuários - 4% sobre todos os depósitos efetuados.
            </span>
            <br />
            <span>
              <b>
                Com o indique e ganhe você pode transformar sua diversão em uma ótima fonte de
                renda!!!
              </b>
            </span>
            <br />
            <span>
              <b>Regras Gerais:</b>
            </span>
            <span>I-O jogador se torna ativo após efetuar ao menos 1 depósito.</span>
            <span>
              II-O usuário permanece ativo efetuando ao menos 1 compra a cada 2 meses (60) dias.
            </span>
            <span>III-Os valores obtidos com suas comissões podem ser resgatados.</span>
            <span>IV-Pedido mínimo para saque é de R$20,00</span>
            <span>
              V-Em caso de fraude em nosso programa de afiliados todo saldo obtido de comissões será
              retirado e sua conta será excluída de nosso jogo.
            </span>
            <span>
              VI-Todos os novos usuários indicados por você devem respeitar nosso Termos e
              Condições.
            </span>
            <span>
              Em caso de dúvidas, entre em contato com nosso chat de atendimento que estamos à
              disposição
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indique;
