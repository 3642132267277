import { takeLatest, all, call, put } from "redux-saga/effects";
import { PRODUCTS, IProducts } from "./types";
import { getProductsError, getProductsFullfilled } from "./actions";
//import Games from "./api";

import { loadProducts } from "./api";

export function* productsSaga() {
  yield all([yield takeLatest(PRODUCTS, listProducts)]);
}

function* listProducts() {
  try {
    const { data }: { data: IProducts[] } = yield call(loadProducts);
    yield put(getProductsFullfilled(data));
  } catch (err) {
    yield put(getProductsError(err));
    //const error = rejectApi(err);
    console.error("error", err);
  }
}
