import React from "react";
import classnames from "classnames";
import { IAttributes } from "../../global";
import "./Bullet.scss";

interface IBulletProps extends IAttributes {
  n: number;
}

const Bullet: React.FC<IBulletProps> = ({ n }) => {
  if (n === 0) return null;

  const isOverflowed = n > 99;

  return (
    <div
      className={classnames("bullet", {
        "bullet--overflowed": isOverflowed,
      })}
    >
      <span className="bullet__text">{n}</span>
    </div>
  );
};

Bullet.defaultProps = {
  n: 0,
};

export default Bullet;
