import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IRootState } from "../../state/ducks/types";

import Banner from "../../components/Banner/Banner";

import WhoWinning from "../../components/WhoWinning";

import "./Home.scss";

import Countdown from "react-countdown";
import { padCounter } from "../../utils/Functions";

import ListaCategorias from "../Categoria/ListaCategorias";
import Button from "../../components/Button";
import Lobby from "../Lobby";

import { TIMEOUT_LIMIT } from "../../state/ducks/global/types";

const Home: FC = () => {
  const { loggedIn } = useSelector((state: IRootState) => state.global);

  const [timeoutCountdown, setTimeoutCountdown] = useState<number>(0);
  const [dateNow, setDateNow] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    let timeoutStorageLimit = localStorage.getItem(TIMEOUT_LIMIT);
    if (timeoutStorageLimit === null) {
      timeoutStorageLimit = Date.now().toString();
      localStorage.setItem(TIMEOUT_LIMIT, timeoutStorageLimit);
    }
    setDateNow(Number(timeoutStorageLimit));
  }, []);

  useEffect(() => {
    if (dateNow > 0) {
      const dateAux: number = dateNow + 7200000 - Date.now();
      let hours: number = Number((dateAux / (1000 * 60 * 60)).toFixed(1));
      if (hours > 0) {
        setTimeoutCountdown(Date.now() + dateAux);
      } else {
        renderCountdown();
      }
      setCurrentIndex(currentIndex + 1);
    }
  }, [dateNow]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCountdown = () => {
    localStorage.setItem(TIMEOUT_LIMIT, Date.now().toString());
    setTimeoutCountdown(Date.now() + 7200000);
  };

  return (
    <div className="home">
      <div className="container column">
        <Banner />
        <Lobby />
        <WhoWinning />
        {!loggedIn && (
          <div className="wrap-newsletter row aic jcc">
            <div className="block block1">
              <h2>Crie agora sua conta e ganhe bônus em todos os depósitos para sempre.</h2>
              <div className="row validate align-items-center justify-content-center">
                <h3>
                  Promoção <br />
                  válida até:
                </h3>
                <Countdown
                  date={timeoutCountdown}
                  key={currentIndex}
                  onComplete={() => {
                    renderCountdown();
                  }}
                  renderer={({ hours, minutes, seconds }) => {
                    return (
                      <div className="row timebox">
                        <div>
                          <span className="number">{padCounter(hours)}</span>
                          <span className="letter">H</span>
                        </div>
                        <div>
                          <span className="number">{padCounter(minutes)}</span>
                          <span className="letter">M</span>
                        </div>
                        <div>
                          <span className="number">{padCounter(seconds)}</span>
                          <span className="letter">S</span>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <Link to="/cadastro">
              <Button text={"CRIAR CONTA"} />
            </Link>
          </div>
        )}
        <ListaCategorias limit={true} value={4} slugCategoria={""} />
      </div>
    </div>
  );
};

export default Home;
