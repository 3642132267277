import React, { FC, useEffect, useState, useRef, Fragment } from "react";

import "./TemaCachorro.scss";
import BuyCard from "./BuyCard";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../state/ducks/types";
import Card from "../../components/Card";
import Chat from "../Chat";
import { IWinner, EnumGameStatus } from "../../state/ducks/games/types";
import { getGameCards } from "../../state/ducks/gameCards/actions";

import _ from "lodash";
import classNames from "classnames";
import { formatCurrency } from "../../utils/Functions";

import WaitNextGame from "../../components/VideoBingo/WaitNextGame";
import RenderCounter from "../../components/VideoBingo/RenderCounter";
import BallsListSections from "../../components/VideoBingo/BallsListSections";
import ComoJogar from "./ComoJogar";

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TemaCachorro: FC = () => {
  const dispatch = useDispatch();

  const audioRef = useRef<HTMLAudioElement>(null);

  const { playerCards } = useSelector((state: IRootState) => state.gameCards);
  const { bingoGame, winners } = useSelector((state: IRootState) => state.games);
  const gameStatus: string | undefined = bingoGame ? bingoGame["bngdog"]?.status : undefined;
  const totalCard: number = 4;
  const allowBuyCard: boolean = playerCards.length < totalCard;
  const [balls, setBalls] = useState<number[]>([]);
  const [showLastWinners, setShowLastWinners] = useState<boolean>(false);
  const [playerOnGame, setPlayerOnGame] = useState<boolean>(false);
  const [soundActive, setSoundActive] = useState<boolean>(false);
  const [audioBalls, setAudioBalls] = useState<HTMLAudioElement[]>([]);
  const { ballsDrawn } = useSelector((state: IRootState) => state.drawBall);

  const oldGameStatus = usePrevious(gameStatus);

  useEffect(() => {
    if (gameStatus === EnumGameStatus.STARTED) {
      if (soundActive && oldGameStatus !== undefined && oldGameStatus !== EnumGameStatus.STARTED) {
        audioBalls[0]?.play();
      }
    }

    if (
      gameStatus === EnumGameStatus.CANCELED ||
      gameStatus === EnumGameStatus.COMPLETED ||
      gameStatus === EnumGameStatus.CONCLUDED ||
      gameStatus === EnumGameStatus.OPENED ||
      gameStatus === EnumGameStatus.FINISHED
    ) {
      setBalls([]);
    }
  }, [audioBalls, gameStatus, oldGameStatus, soundActive]);

  const handlerBuyCardClick = () => {
    if (allowBuyCard && gameStatus === EnumGameStatus.OPENED) {
      dispatch(getGameCards("bngdog"));
    }
  };

  const handlerActiveSound = () => {
    if (audioRef?.current) {
      audioRef.current.volume = 0.2;
      audioRef.current?.play();

      const audioBalls: HTMLAudioElement[] = [];

      for (let i = 0; i <= 75; i++) {
        const add = new Audio(`/audios/v1/${i}.mp3`);
        add.load();
        audioBalls.push(add);
      }
      setAudioBalls(audioBalls);
      setSoundActive(true);
    }
  };

  useEffect(() => {
    if (
      bingoGame &&
      bingoGame["bngdog"]?.draw_numbers &&
      !_.isEqual(balls, bingoGame["bngdog"]?.draw_numbers)
    ) {
      setBalls(bingoGame["bngdog"]?.draw_numbers);
    }
  }, [bingoGame?.draw_numbers, balls, bingoGame]);

  useEffect(() => {
    if (playerCards) {
      setPlayerOnGame(
        playerCards.some((item) => {
          return item.on_game === 1;
        })
      );
    }
  }, [playerCards]);

  const buyCardsSections = () => {
    return (
      <Fragment>
        {playerCards &&
          playerCards.length > 0 &&
          gameStatus === EnumGameStatus.OPENED &&
          playerOnGame && (
            <div className="ball-screen-phrase d-m-none">
              <b>PRÊMIO BOLA NA TELA</b> <br /> ESCOLHA UM NÚMERO EM CADA CARTELA
            </div>
          )}
        <div className="buy-cards">
          {playerCards &&
            playerCards.map((card, key) => (
              <Card
                key={key}
                id={card.id}
                numbers={card.numbers}
                statusFull={card.status_full}
                statusBallScreen={card.status_ball_screen}
                statusLine={card.status_line}
                statusCorner={card.status_corner}
                roomId={card.bingo_game_room_id}
                ballScreen={card.ball_screen}
                lineBingo={card.bingo_line}
                cornerBingo={card.bingo_corner}
                prize={card.bingoCard.prize_full}
                roundId={card.bingoGame?.round_id}
                ballsDrawn={ballsDrawn ? ballsDrawn["bngdog"] : []}
                gameCode="bngdog"
              />
            ))}
          {allowBuyCard &&
            gameStatus === EnumGameStatus.OPENED &&
            [...Array(totalCard - (playerCards.length ?? 0))].map((_, key) => (
              <BuyCard key={key} index={key} type="text" />
            ))}
        </div>
      </Fragment>
    );
  };

  const chatSection = () => {
    return (
      <div className="wrap-chat">
        <div className="top"></div>
        <Chat />
        <div className="bottom"></div>
      </div>
    );
  };

  const renderRowWinner = (
    round_id: number,
    full: IWinner[],
    corner: IWinner[],
    ball_screen: IWinner[],
    line: IWinner[]
  ) => {
    const rows = [];
    rows.push(rowWinner(round_id, full, "Cartela Cheia"));
    rows.push(rowWinner(round_id, corner, "4 Cantos"));
    rows.push(rowWinner(round_id, ball_screen, "Bola na tela"));
    rows.push(rowWinner(round_id, line, "Linha"));
    return rows;
  };

  const rowWinner = (round_id: number, row: IWinner[], type: string) => {
    return row.map((item, k) => {
      return (
        <div className="row body" key={k}>
          <div className="col col-3 col-last">{round_id}</div>
          <div className="col col-3 col-last">{item.bingo_game_room_id}</div>
          <div className="col col-5 col-last">{item.nickname}</div>
          <div className="col col-4 col-last">
            {item.amount ? formatCurrency(item.amount) : "-"}
          </div>
          <div className="col col-5 col-last">{item.winners_total ? item.winners_total : "-"}</div>
          <div className="col col-5 col-last">{type}</div>
        </div>
      );
    });
  };

  const lastWinnerBox = () => {
    return (
      <div className={classNames("wLastWinners", { active: showLastWinners })}>
        <span className="close" onClick={() => handleShowLastWinners()}></span>
        <div className="lastWinners">
          <div className="row head">
            <div className="col col-3 col-last">Rodada</div>
            <div className="col col-3 col-last">Sala</div>
            <div className="col col-5 col-last">Usuário</div>
            <div className="col col-4 col-last">Valor</div>
            <div className="col col-5 col-last">Total Vencedores</div>
            <div className="col col-5 col-last">Tipo</div>
          </div>
          {winners?.map((item) => {
            return item?.winners.map((win) => {
              return renderRowWinner(
                item.round_id,
                win.full,
                win.corner,
                win.ball_screen,
                win.line
              );
            });
          })}
        </div>
      </div>
    );
  };

  const handleShowLastWinners = () => {
    setShowLastWinners(!showLastWinners);
  };

  return (
    <div className="theme theme-cachorro">
      <audio ref={audioRef} src="/audio-fundo.mp3" loop />
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="true"
        className="bg-video"
        poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      >
        <source src="/tema-cachorro/bg.mp4" type="video/mp4" />
      </video>
      <div className="container">
        <div className="row">
          <div className="col col-9 col-m-25 order-m-3 col-m-last">
            <div className="d-m-none d-block">{chatSection()}</div>
          </div>
          <div className="col col-8 col-m-9 order-m-1 col-m-last column justify-content-end justify-content-m-start">
            <div
              className={classNames("d-block", "d-m-none", {
                margin_auto_balls:
                  (gameStatus === EnumGameStatus.STARTED ||
                    gameStatus === EnumGameStatus.FINISHED) &&
                  ((playerOnGame && playerCards.length > 0) || playerCards.length === 0),
              })}
            >
              <BallsListSections
                gameStatus={gameStatus}
                playerOnGame={playerOnGame}
                balls={balls}
                audioBalls={audioBalls}
                cards={playerCards.length}
              />
            </div>

            <div className="d-none d-m-block">
              <WaitNextGame gameStatus={gameStatus} totalCards={playerCards.length} />
            </div>

            {gameStatus === EnumGameStatus.OPENED && (
              <React.Fragment>
                {playerCards.length === 4 ? (
                  <div className="box-prix">
                    <h3>
                      <span>AGUARDE O INÍCIO DO</span> <br /> PRÓXIMO SORTEIO
                    </h3>
                    <RenderCounter
                      scheduledDate={bingoGame ? bingoGame["bngdog"]?.scheduled_date : undefined}
                    />
                  </div>
                ) : (
                  <div className="box-prix box-prix-buy" onClick={() => handlerBuyCardClick()}>
                    <h3>
                      <span>COMPRE</span> <br /> CARTELAS
                    </h3>
                    <h4>
                      <span>PARA A</span> <br /> PRÓXIMA RODADA
                    </h4>
                    <RenderCounter
                      scheduledDate={bingoGame ? bingoGame["bngdog"]?.scheduled_date : undefined}
                    />
                  </div>
                )}
              </React.Fragment>
            )}

            <div className="d-block d-m-none">
              {((gameStatus === EnumGameStatus.STARTED &&
                playerCards.length > 0 &&
                !playerOnGame) ||
                gameStatus === EnumGameStatus.CONCLUDED) && (
                <div className="box-prix">
                  <h3>
                    <span>JOGO FINALIZADO</span>
                  </h3>
                  <br />
                  <h4>
                    <span>AGUARDE O INÍCIO DO</span>
                    <br /> PRÓXIMO SORTEIO
                  </h4>
                </div>
              )}
            </div>

            {gameStatus === EnumGameStatus.STARTING && (
              <div className="box-prix">
                <h3>
                  <span>INICIANDO JOGO</span>
                </h3>
              </div>
            )}

            <div className="d-none d-m-row flex-m-column">{buyCardsSections()}</div>

            <div className="d-block d-m-none">
              <img src="/tema-cachorro/dog-bottom.gif" alt="Imagem cachorro" className="img-dog" />
              <ComoJogar color="#8aff00" />
            </div>
          </div>
          <div className="col col-8 col-last col-m-16 order-m-2 col-m-last">
            {!soundActive && (
              <div className="box-prix sound" onClick={() => handlerActiveSound()}>
                <h3>
                  <span>
                    <span className="material-icons">volume_up</span>ATIVAR SOM
                  </span>
                </h3>
              </div>
            )}
            <div className="wtop-bar">
              <div className="top-bar row aic jcc" onClick={() => handleShowLastWinners()}>
                + ÚLTIMOS <span>GANHADORES</span>
              </div>
              {lastWinnerBox()}
            </div>

            <div className="d-block d-m-none">
              {gameStatus === EnumGameStatus.STARTED && playerCards.length === 0 && (
                <WaitNextGame gameStatus={gameStatus} totalCards={playerCards.length} />
              )}
            </div>

            <div className="d-none d-m-block">
              <BallsListSections
                gameStatus={gameStatus}
                playerOnGame={playerOnGame}
                balls={balls}
                audioBalls={audioBalls}
                cards={playerCards.length}
              />
            </div>

            <div className="d-m-block d-none">
              <ComoJogar color="#8aff00" />
              {chatSection()}
              <img src="/tema-cachorro/dog-bottom.gif" alt="Imagem cachorro" className="img-dog" />
              {playerCards &&
                playerCards.length > 0 &&
                gameStatus === EnumGameStatus.OPENED &&
                playerOnGame && (
                  <div className="ball-screen-phrase">
                    <b>PRÊMIO BOLA NA TELA</b> <br /> ESCOLHA UM NÚMERO EM CADA CARTELA
                  </div>
                )}
            </div>

            <div className="d-block d-m-none">{buyCardsSections()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemaCachorro;
