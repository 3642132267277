import React, { FC, useEffect,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { IRootState } from "../../state/ducks/types";

import { IGames } from "../../state/ducks/games/types";
import ApiClient from "../../services/ApiClient";
import { getGameUrl } from "../../state/ducks/games/actions";

export interface IGameProps {
    code: string;
    height: number
  }

const Game: FC<IGameProps> = ({ code, height }) => {
  const dispatch = useDispatch();

  const { gameUrl } = useSelector(
    (state: IRootState) => state.games
  );

  const { loggedIn, player: { id } } = useSelector((state: IRootState) => state.global);

  const [game, setGame] = useState<IGames>();
  const [popupGame, setPopupGame] = useState<Window | null>();
  const [jogoUrl, setJogoUrl] = useState<string>("");

  useEffect(() => {
    const loadGame = async (code: string) => {
        try {
        const response = await ApiClient.get(`games/${code}`)
        setGame(response.data)
        } catch (error) {
        console.log(error)
        }
    };

    loadGame(code)
  }, [code]);

  useEffect(() => {
    if (gameUrl.url !== "") {
      if (gameUrl.popup === 1) {
        setJogoUrl(gameUrl.url);
      } else {
        setJogoUrl("");
      }
    }
  }, [gameUrl, dispatch]);

  const openJogo = (gameId: number, popup: boolean) => {
    if (popup && !isMobile) {
    const h = window.screen.height * 0.7;
    const w = h * (16 / 9);
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    const popup = window.open(
        "",
        !isMobile ? "game" : "_blank",
        !isMobile
        ? `location=no,toolbar=0,status=0,width=${w},height=${h},left=${left},top=${top}`
        : ""
    );
    setPopupGame(popup);
    }
    dispatch(getGameUrl(gameId));
  };

  useEffect(() => {
    if (!loggedIn && popupGame) {
      popupGame.close();
    }
    if (jogoUrl) {
      if (isMobile) {
        document.location.href = jogoUrl;
      } else {
        if (popupGame) {
          popupGame.location.href = jogoUrl;
        }
      }
    }
  }, [jogoUrl, dispatch, popupGame, loggedIn]);

  if(!game || (!game.active && id !== 1)) return <></>

  return <img style={{ cursor: 'pointer' }} src={game.image_url} alt={game.name} height={height} onClick={() => openJogo(game.id, game.gameProvider.external === 1)} />

};

export default Game;
