import React, { FC } from "react";

const JogoResponsavel: FC = () => {
  return (
    <div className="institucional">
      <div className="container">
        <h1>Jogo Responsável</h1>
        <p className="center">Nosso compromisso com nossos clientes.</p>
        <p>
          Aqui no Bingotine, estamos empenhados em promover o jogo como forma alternativa de
          entretenimento e lazer, onde comportamento do jogo seja socialmente responsável.
        </p>
        <p>
          O jogo é mais seguro e muito mais divertido quando você está ciente dos riscos envolvidos.
        </p>
        <p>
          Pensando em ajudar nossos jogadores com potencial problemas de jogos, aconselhamos sempre
          que apostem por diversão e nunca pelo dinheiro. O jogo deve ser visto como uma forma de
          entretenimento e diversão e não como uma maneira de ganhar dinheiro.
        </p>
        <p>Divirta-se jogando com responsabilidade. Jogo responsável, mantenha-se no controle</p>

        <p className="center">Dicas sobre jogo responsável</p>
        <p>
          <b>Aprenda como os jogos são jogados.</b> <br />
          Não faça grandes apostas antes de entender como um jogo é jogado, as chances de vitória e
          a vantagem da casa. Por favor, pergunte a nosso suporte sobre como os jogos são jogados e
          como as apostas são feitas.
        </p>
        <p>
          <b> Leve um estilo de vida equilibrado.</b> <br /> Desfrute de diferentes tipos de
          atividades de lazer além do jogo. Encontre outros tipos de formas de entretenimento
          relaxantes ou emocionantes para aprimorar seu estilo de vida.
        </p>
        <p>
          <b>Defina um limite de tempo e dinheiro.</b> <br /> Só aposte dinheiro que você pode
          perder. Nunca persiga as perdas. Saiba a que horas você deve se levantar e parar de jogar.
        </p>
        <p>
          <b> Jogos e álcool não são uma boa combinação.</b> <br /> O jogo aqui no Bingotine deve
          ser feito com uma mente clara e calma. Um pouco de álcool em excesso pode prejudicar seu
          julgamento e levá-lo a fazer apostas arriscadas.
        </p>
        <p>
          <b>Conseguindo ajuda</b> <br />
          Se você acha que você ou um membro da família tem um problema com jogos, sempre procure
          ajuda:
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="https://jogadoresanonimos.com.br">
            https://jogadoresanonimos.com.br
          </a>
          <br />
          <a target="_blank" rel="noreferrer" href="http://www.iaj.pt">
            http://www.iaj.pt
          </a>{" "}
          <br />
          <a
            href="https://gruposalvandovidas.com.br/tratamento-de-viciados-em-jogos"
            target="_blank"
            rel="noreferrer"
          >
            https://gruposalvandovidas.com.br/tratamento-de-viciados-em-jogos
          </a>
        </p>
        <p>
          <b> Você ainda tem mais perguntas?</b> <br />
          Se depois de ler nossa seção de jogo responsável e nosso termos e condições, caso tenho
          alguma dúvida, por favor sinta-se à vontade para entrar em contato conosco através de
          nosso suporte ao cliente via WhatsApp: +55 11 – 9.9140-6564 ou +55 11- 9.8883-0364
        </p>
      </div>
    </div>
  );
};

export default JogoResponsavel;
