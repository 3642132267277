import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../state/ducks/types";

import { IAttributes } from "../../global";
import Button from "../Button";

import { formatCurrency } from "../../utils/Functions";

import "./DepositoLine.scss";

export interface IDepositoLineProps extends IAttributes {
  compre: string;
  ganhe: string;
  imagem?: string;
  valor: number;
  addButton?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const DepositoLine: FC<IDepositoLineProps> = ({ compre, ganhe, valor, addButton, onClick }) => {
  const { loading } = useSelector((state: IRootState) => state.deposits);

  return (
    <div className="line row aic flex-m-wrap">
      <div className="col col-5 col-margin-0 col-m-8 row aic col-m-padding-0">
        <div>
          <span className="txt buy">Compre {compre}</span>
          {parseFloat(ganhe) > 0 && (
            <span className="txt win">
              <img src="/estrela.png" alt="estrela" />+{ganhe} bônus
            </span>
          )}
        </div>
      </div>
      <div className="col col-6 col-margin-0 col-m-6 row aic col-m-padding-0">
        <img src={`/moeda${valor}.png`} alt="Compre e ganhe" className="img" />
      </div>
      <div className="col col-margin-0 col-4 col-m-7 col-m-last col-m-padding-0">
        <span className="txt price">{formatCurrency(valor)}</span>
      </div>
      <div className="col col-margin-0 col-last col-m-25 col-m-last">
        {addButton && <Button onClick={onClick} text={loading ? "PROCESSANDO.." : "DEPOSITAR"} />}
        {/*addButton && (
          <a
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=5511991406564&text=Olá, quero depositar R$${valor}`}
            rel="noreferrer"
          >
            <Button text={loading ? "PROCESSANDO.." : "DEPOSITAR"} />
          </a>
        )*/}
      </div>
    </div>
  );
};

export default DepositoLine;
