import { takeLatest, all, put, call } from "redux-saga/effects";

import _ from "lodash";
import { rejectApi, getErrorMessage } from "../../../utils/Functions";

import {
  CHECKOUT_ORDER_REQUEST,
  EnumCheckoutType,
  GET_CUPONS_REQUEST,
  GET_PACKS_REQUEST,
  ICheckoutItem,
  ICheckoutOrderRequest,
  IRescueCupomRequest,
  RESCUE_CUPOM_REQUEST,
} from "./types";
import CheckoutService from "./api";
import {
  getPacksFulfilled,
  getPacksRejected,
  openModalAction,
  checkoutOrderFulfilled,
  checkoutOrderRejected,
  getCuponsFulfilled,
  getCuponsRejected,
  rescueCupomSuccess,
  rescueCupomRejected,
} from "./actions";

function* getPacksRequestWorker() {
  try {
    const { data } = yield call(CheckoutService.getPacks);

    yield put(getPacksFulfilled(data));
    yield put(openModalAction());
  } catch (error) {
    rejectApi(error);
    yield put(getPacksRejected(error));
  }
}
function* getPacksRequest() {
  yield takeLatest(GET_PACKS_REQUEST, getPacksRequestWorker);
}

function* getCuponsRequestWorker() {
  try {
    const { data } = yield call(CheckoutService.getCupons);

    yield put(getCuponsFulfilled(data));
    yield put(openModalAction());
  } catch (error) {
    rejectApi(error);
    yield put(getCuponsRejected(error));
  }
}
function* getCuponsRequest() {
  yield takeLatest(GET_CUPONS_REQUEST, getCuponsRequestWorker);
}

function* checkoutOrderWorker({ payload }: ICheckoutOrderRequest) {
  const { checkout, card, method, type } = payload;

  try {
    const formattedCheckout = checkout.map((item: ICheckoutItem) => ({
      ...(type === EnumCheckoutType.Produtos ? { produtosId: item.id } : { cuponsId: item.id }),
      quantidade: item.quantity,
    }));

    if (method === "cartao") {
      let body: any;
      if (card?.token) {
        let cartao: string | undefined;
        switch (card?.issuer) {
          case "mastercard":
            cartao = "master";
            break;
          default:
            cartao = card?.issuer;
        }

        body = {
          items: formattedCheckout,
          dadosCartao: {
            token: card.token,
            cartao,
            cpf: card.cpf,
          },
        };
      } else {
        const expiry = _.split(card?.expiry, "/");
        const dataExpiracao = `${expiry[0]}/${parseInt(expiry[1]) + 2000}`;
        let cartao: string | undefined;
        switch (card?.issuer) {
          case "mastercard":
            cartao = "master";
            break;
          case "dinersclub":
            cartao = "diners";
            break;
          default:
            cartao = card?.issuer;
        }
        const formattedCard = {
          cartao: _.upperCase(cartao),
          numero: card?.number?.replace(/\s/g, ""),
          dataExpiracao: dataExpiracao,
          titular: card?.name,
          codigoSeguranca: card?.cvc,
          cpf: card?.cpf,
        };
        body = {
          items: formattedCheckout,
          dadosCartao: formattedCard,
        };
      }
      const { data } = yield call(CheckoutService.sendOrder, { checkout: { ...body } }, method);
      yield put(checkoutOrderFulfilled(data));
    } else {
      const body = {
        items: formattedCheckout,
      };
      const { data } = yield call(CheckoutService.sendOrder, { checkout: { ...body } }, method);
      yield put(checkoutOrderFulfilled(data));
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(checkoutOrderRejected(errorMessage));
  }
}
function* checkoutOrder() {
  yield takeLatest(CHECKOUT_ORDER_REQUEST, checkoutOrderWorker);
}

function* rescueCupomRequestWorker({ payload }: IRescueCupomRequest) {
  try {
    const { data } = yield call(CheckoutService.rescueCupom, payload.codigo);
    yield put(rescueCupomSuccess(data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put(rescueCupomRejected(errorMessage));
  }
}
function* rescueCupomRequest() {
  yield takeLatest(RESCUE_CUPOM_REQUEST, rescueCupomRequestWorker);
}

export default function* checkoutSaga() {
  yield all([getPacksRequest(), checkoutOrder(), getCuponsRequest(), rescueCupomRequest()]);
}
