import React, { Fragment, FC, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./Videobingo.scss";

import TemaGatinho from "./TemaGatinho";
import TemaCachorro from "./TemaCachorro";
import { useParams } from "react-router";
import ModalGameCards from "../../components/Modals/ModalGameCards";
import { getPlayerCards } from "../../state/ducks/gameCards/actions";
import { getVideoBingoStatus, cleanWinner, cleanLoser } from "../../state/ducks/games/actions";
import { IRootState } from "../../state/ducks/types";
import { EnumGameWin, EnumGameLoser, IWinner } from "../../state/ducks/games/types";
import FastbingoV1 from "../Fastbingo/FastbingoV1";

const Videobingo: FC = () => {
  const { slug }: { slug: string } = useParams();

  const dispatch = useDispatch();

  const {
    winner,
    winnerPosition,
    loser,
    loserPosition,
    winnersBallScreen,
    winnersLine,
    winnersCorner,
    winnersFull,
  } = useSelector((state: IRootState) => state.games);

  const winnerPopup = () => {
    let imageWinner: string = "";
    if (winnerPosition === EnumGameWin.BOLATELA) {
      imageWinner = "bolanatela.png";
    } else if (winnerPosition === EnumGameWin.CARTELACHEIA) {
      imageWinner = "cartelacheia.png";
    } else if (winnerPosition === EnumGameWin.LINHA) {
      imageWinner = "linha.png";
    } else if (winnerPosition === EnumGameWin.QUATROCANTOS) {
      imageWinner = "4cantos.png";
    }
    return (
      <div className="winnerPopup">
        <img src={`/${imageWinner}`} alt="Imagem Final" />
      </div>
    );
  };

  const loserPopup = () => {
    let imageLoser: string = "";
    let winners: IWinner[] | null = null;
    if (loserPosition === EnumGameLoser.BOLATELA) {
      imageLoser = "naofoidessavez-bolanatela.png";
      winners = winnersBallScreen;
    } else if (loserPosition === EnumGameLoser.CARTELACHEIA) {
      imageLoser = "naofoidessavez-cartelacheia.png";
      winners = winnersFull;
    } else if (loserPosition === EnumGameLoser.LINHA) {
      imageLoser = "naofoidessavez-linha.png";
      winners = winnersLine;
    } else if (loserPosition === EnumGameLoser.QUATROCANTOS) {
      imageLoser = "naofoidessavez-4cantos.png";
      winners = winnersCorner;
    }
    return (
      <div className="winnerPopup">
        {loserPosition === EnumGameLoser.BOLATELA && winners?.length === 0 ? (
          <p>Ninguém acertou.</p>
        ) : (
          <p>{winners?.map((w) => w.nickname).join(", ")}</p>
        )}
        <img src={`/${imageLoser}`} alt="Imagem Final" />
      </div>
    );
  };

  useEffect(() => {
    if (winner) {
      setTimeout(() => {
        dispatch(cleanWinner());
      }, 2500);
    }
  }, [winner, dispatch]);

  useEffect(() => {
    if (loser) {
      setTimeout(() => {
        dispatch(cleanLoser());
      }, 2500);
    }
  }, [loser, dispatch]);

  useEffect(() => {
    dispatch(getVideoBingoStatus(slug));
    dispatch(getPlayerCards(slug));
  }, [slug, dispatch]);

  if (slug === "bngcat") {
    return (
      <Fragment>
        {winner && winnerPopup()}
        {loser && loserPopup()}
        <TemaGatinho />
        <ModalGameCards />
      </Fragment>
    );
  } else if (slug === "bngdog") {
    return (
      <Fragment>
        {winner && winnerPopup()}
        {loser && loserPopup()}
        <TemaCachorro />
        <ModalGameCards />
      </Fragment>
    );
  } else if (slug === "bngfast1") {
    return <FastbingoV1 />;
  } else {
    return <Redirect to={`/categoria/jogos-de-bingo`} />;
  }
};

export default Videobingo;
