import Payment from "payment";
import _ from "lodash";
import { EnumPlayerCardStatus } from "../state/ducks/games/types";

// work in progress, rotateMatrix of numbers
const formatCurrency = (value: number, currency?: string) => {
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value);
};

const formatDigits = (value: number) => {
  const newValue: any = value.toFixed(2);
  return newValue.replace(".", ",");
};

const rejectApi = (err: any, reject?: Function): string => {
  const error =
    err?.response?.data?.message || err || "Não foi possível se conectar com o servidor";
  if (reject) reject(error);
  return error;
};

const getErrorMessage = (err: any): string => {
  const error =
    err?.response?.data?.status?.message || err || "Não foi possível se conectar com o servidor";
  return error;
};

const padCounter = (value: number) => value.toString().padStart(2, "0");

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export const urlEncode = (params: any) => {
  var lod = _.omitBy(params, _.isNull);
  var query = Object.keys(lod)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(lod[key])}`)
    .join("&");
  return query;
};

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        15
      )}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        14
      )}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12
      )} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

function formatCVC(value: string, number: any) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (number) {
    const issuer = Payment.fns.cardType(number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

function formatExpirationDate(value: string) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

function formatFormData(data: string) {
  return Object.keys(data).map((d) => `${d}: ${data[parseInt(d)]}`);
}

function formatDecimal(value: string): number {
  let valor = value.replace(".", "");
  valor = valor.replace(",", ".");
  return parseFloat(valor);
}

function checkGoodOne(
  drawNumbers: number[],
  cardNumbers: (number | null)[][] | undefined,
  statusLine?: string,
  statusCorner?: string
) {
  if (!cardNumbers) return false;
  let goodOne = false;
  goodOne = _.size(_.intersection(drawNumbers, _.compact(_.flatten(cardNumbers)))) >= 23;

  if (goodOne) return true;

  if (statusLine === EnumPlayerCardStatus.ACTIVE) {
    goodOne = cardNumbers.some((l: any, key: number) => {
      const qtdeNumeros = key === 2 ? 3 : 4;
      if (_.size(_.intersection(drawNumbers, _.compact(l))) === qtdeNumeros) {
        return true;
      }
      return false;
    });

    if (goodOne) return true;

    const cardNumbersInvert = cardNumbers
      ? cardNumbers[0].map((_: any, colIndex: number) =>
          cardNumbers.map((row: any) => row[colIndex])
        )
      : [];

    goodOne = cardNumbersInvert.some((l: number[], key: number) => {
      const totalNumbers = key === 2 ? 3 : 4;
      if (_.size(_.intersection(drawNumbers, _.compact(l))) === totalNumbers) {
        return true;
      }
      return false;
    });

    if (goodOne) return true;
  }

  if (statusCorner === EnumPlayerCardStatus.ACTIVE) {
    const corner: number[] = [];
    _.map(cardNumbers, (l: number[], key: number) => {
      if (key === 0 || key === 4) {
        corner.push(l[0]);
        corner.push(l[4]);
      }
    });
    if (_.size(_.intersection(drawNumbers, _.compact(corner))) === 3) {
      return true;
    }
  }

  return false;
}

export {
  formatCurrency,
  rejectApi,
  padCounter,
  getErrorMessage,
  formatCVC,
  formatExpirationDate,
  formatFormData,
  formatDecimal,
  formatDigits,
  checkGoodOne,
};
