export const WITHDRAW = "WITHDRAW";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";
export const WITHDRAW_FULLFILLED = "WITHDRAW_FULLFILLED";

export const WITHDRAW_RESET = "WITHDRAW_RESET";

export interface IWithDrawState {
  loading: boolean;
  error: string;
  success: boolean;
}

export interface IWithDrawction {
  type: typeof WITHDRAW;
  payload: IWithDrawService;
}

export interface IWithDrawService {
  amount: string;
  email: string;
  pix_key: string;
  pix_key_type: string;
  withdraw_type: string;
}

export enum EnumWithDrawType {
  WITHDRAW_PREMIO = "PRÊMIO",
  WITHDRAW_COMISSAO = "COMISSÃO",
}
