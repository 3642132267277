import { IReduxAction } from "../../../global";
import {
  ICheckoutState,
  EnumCheckoutStates,
  OPEN_MODAL_CHECKOUT,
  CLOSE_MODAL_CHECKOUT,
  GET_PACKS_REQUEST,
  GET_PACKS_FULFILLED,
  GET_PACKS_REJECTED,
  GET_CUPONS_REQUEST,
  GET_CUPONS_FULFILLED,
  GET_CUPONS_REJECTED,
  RESCUE_CUPOM_REQUEST,
  RESCUE_CUPOM_SUCCESS,
  RESCUE_CUPOM_REJECTED,
  CHECKOUT_ORDER_REQUEST,
  CHECKOUT_ORDER_REJECTED,
  CHECKOUT_ORDER_FULFILLED,
  CHECKOUT_PAYMENT_FORM,
  EnumCheckoutType,
} from "./types";

const initialState: ICheckoutState = {
  state: EnumCheckoutStates.Stable,
  error: undefined,
  modal: {
    isOpen: false,
  },
  packs: [],
  items: [],
  order: undefined,
  card: undefined,
  method: "",
  type: "",
};

export default function checkoutReducer(
  state = initialState,
  { type, payload }: IReduxAction
): ICheckoutState {
  switch (type) {
    case OPEN_MODAL_CHECKOUT:
      return {
        ...state,
        modal: { isOpen: true },
      };
    case CLOSE_MODAL_CHECKOUT:
      return initialState;

    case GET_PACKS_REQUEST: {
      return {
        ...state,
        error: undefined,
        packs: [],
        state: EnumCheckoutStates.LoadingPacks,
        checkoutType: EnumCheckoutType.Produtos,
      };
    }
    case GET_PACKS_FULFILLED: {
      return {
        ...state,
        packs: payload?.packs,
        state: EnumCheckoutStates.Stable,
      };
    }
    case GET_PACKS_REJECTED: {
      return {
        ...state,
        error: payload?.error,
        state: EnumCheckoutStates.ErrorPacks,
      };
    }

    case GET_CUPONS_REQUEST: {
      return {
        ...state,
        error: undefined,
        packs: [],
        cupons: [],
        state: EnumCheckoutStates.LoadingPacks,
        checkoutType: EnumCheckoutType.Cupons,
      };
    }
    case GET_CUPONS_FULFILLED: {
      return {
        ...state,
        cupons: payload?.cupons,
        state: EnumCheckoutStates.Stable,
      };
    }
    case GET_CUPONS_REJECTED: {
      return {
        ...state,
        error: payload?.error,
        state: EnumCheckoutStates.ErrorPacks,
      };
    }

    case RESCUE_CUPOM_REQUEST: {
      return {
        ...state,
        error: undefined,
        cupom: undefined,
        state: EnumCheckoutStates.RescuingCupom,
      };
    }
    case RESCUE_CUPOM_SUCCESS: {
      return {
        ...state,
        error: undefined,
        cupom: payload?.cupom,
        state: EnumCheckoutStates.RescuedCupom,
      };
    }
    case RESCUE_CUPOM_REJECTED: {
      return {
        ...state,
        error: payload?.error,
        state: EnumCheckoutStates.RequestCupom,
      };
    }

    case CHECKOUT_ORDER_REQUEST: {
      return {
        ...state,
        error: payload?.error,
        order: undefined,
        card: payload?.card,
        items: payload?.checkout,
        method: payload?.method,
        type: payload?.type,
        state: EnumCheckoutStates.LoadingOrder,
      };
    }

    case CHECKOUT_PAYMENT_FORM: {
      return {
        ...state,
        items: payload?.items,
        method: payload?.method,
        type: payload?.type,
        state: EnumCheckoutStates.PaymentForm,
      };
    }

    case CHECKOUT_ORDER_FULFILLED: {
      return {
        ...state,
        order: payload?.data,
        state: EnumCheckoutStates.AfterOrder,
      };
    }
    case CHECKOUT_ORDER_REJECTED: {
      return {
        ...state,
        error: payload?.error,
        state: EnumCheckoutStates.ErrorOrder,
      };
    }

    default:
      return state;
  }
}
