import { IReduxAction } from "../../../global";
import { PRODUCTS, PRODUCTS_ERROR, PRODUCTS_FULLFILLED, IProductsState } from "./types";

export const initialState: IProductsState = {
  loading: false,
  products: [],
  errorMessage: "",
};

export default function productsReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IProductsState {
  switch (type) {
    case PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTS_FULLFILLED:
      return {
        ...state,
        loading: false,
        products: payload?.products,
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        products: [],
        errorMessage: payload?.message,
      };
    default:
      return state;
  }
}
