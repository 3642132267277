import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import whoWinning from "../../assets/who-winning.png";

import WinningCard from "../../components/WhoWinning/WinningCard";

import "./WhoWinning.scss";

import { IRootState } from "../../state/ducks/types";
import { formatCurrency } from "../../utils/Functions";
import { listWhosWinning } from "../../state/ducks/games/actions";

import Slider from "react-slick";

const WhoWinning: FC = () => {
  const dispatch = useDispatch();

  const { winnings } = useSelector((state: IRootState) => state.games);

  useEffect(() => {
    dispatch(listWhosWinning());
  }, [dispatch]);

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 6000,
    slidesToShow: winnings.length > 3 ? 3 : winnings.length,
    slidesToScroll: winnings.length > 3 ? 3 : 1,
    draggable: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: winnings.length > 2 ? 2 : winnings.length,
          slidesToScroll: winnings.length > 2 ? 2 : 1,
        },
      },
    ],
  };

  return (
    <div className="who-winning row aic justify-content-between">
      <div className="item-first">
        <WinningCard image={whoWinning} bigText={`Quem está ganhando`} />
      </div>
      <div className="carousel">
        <Slider {...settings}>
          {winnings &&
            winnings.map((item, k) => (
              <WinningCard
                key={k}
                image={item.game.imageUrl}
                username={item.nickname}
                price={formatCurrency(item.amount)}
              />
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default WhoWinning;
