import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { IRootState } from "../state/ducks/types";
import { ROUTE_HOME } from "../config/routes";

import LoginContainer from "../containers/Login";
import { EnumPlayerStatus } from "../state/ducks/global/types";

const LoginRouter: FC = () => {
  const { loggedIn, player: { status } } = useSelector((state: IRootState) => state.global);

  return (!loggedIn || status!==EnumPlayerStatus.ACTIVE) ? (
    <LoginContainer />
  ) : (
    <Redirect
      to={{
        pathname: ROUTE_HOME,
      }}
    />
  );
};

export default LoginRouter;
