import React from "react";
import { useDispatch } from "react-redux";

import Button, { EnumButtonVariants } from "../../Button/Button";
import { tryagainPaymentAction } from "../../../state/ducks/deposits/actions";

const OrderError: React.FC = () => {
  const dispatch = useDispatch();

  const handleClickTryagainButton = () => {
    dispatch(tryagainPaymentAction());
  };

  return (
    <div className="order-success">
      <>
        <div className="order-success__content">
          <h1>Houve um problema na realização do seu pedido :(</h1>
        </div>
        <div className="order-success__footer">
          <Button
            text="TENTE NOVAMENTE"
            variant={EnumButtonVariants.Secondary}
            height={64}
            fontStyle={{
              fontSize: 28,
            }}
            onClick={handleClickTryagainButton}
          />
        </div>
      </>
    </div>
  );
};

export default OrderError;
