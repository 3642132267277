import { IReduxAction } from "../../../global";
import {
  WITHDRAW,
  WITHDRAW_ERROR,
  WITHDRAW_FULLFILLED,
  WITHDRAW_RESET,
  IWithDrawState,
} from "./types";

export const initialState: IWithDrawState = {
  loading: false,
  error: "",
  success: false,
};

export default function withdrawReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IWithDrawState {
  switch (type) {
    case WITHDRAW:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case WITHDRAW_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        success: false,
      };
    case WITHDRAW_FULLFILLED:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case WITHDRAW_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
