import React, { FC, useState } from "react";

import Input from "../../components/Input";

import "./EscolhaCartelas.scss";

export interface IItemProps {
  image: string;
  value: string;
}

const CartelaItem: FC<IItemProps> = ({ image, value }) => {
  const [iniValue, setIniValue] = useState<number>(0);
  return (
    <div className="col col-m-25 col-m-last item column aic">
      <img src={image} alt={`Cartela R$ ${value}`} />
      <p className="value row jcc aic">
        Cartela R$ {value}
        <img src="/cifrao.png" alt={`Cartela R$ ${value}`} width={21} />
      </p>
      <Input
        type="number"
        min={0}
        value={iniValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIniValue(Number(e.target.value))}
      />
    </div>
  );
};

export default CartelaItem;
