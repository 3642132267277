import { IReduxAction } from "../../../global";
import {
  DEPOSITS,
  DEPOSITS_ERROR,
  DEPOSITS_FULLFILLED,
  OPEN_MODAL_DEPOSITS,
  CLOSE_MODAL_DEPOSITS,
  SET_SELECTED_PRODUCT,
  EMPTY_FLAGS,
  TRY_AGAIN_PAYMENT,
  EnumDepositsStates,
  EnumDepositsTab,
  IDepositsState,
} from "./types";

export const initialState: IDepositsState = {
  loading: false,
  error: "",
  method: "",
  modal: {
    isOpen: false,
  },
  card: undefined,
  state: EnumDepositsStates.Stable,
  productId: undefined,
  deposito: {
    id: 0,
    status: "",
    payment_type: "",
    amount: 0,
    player_id: 0,
    depositMpRespone: {
      id: 0,
      status: "",
      payment_id: "",
      qr_code: "",
      qr_code_string: "",
      qr_code_base64: "",
      deposit_id: 0,
    },
  },
};

export default function depositsReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IDepositsState {
  switch (type) {
    case EMPTY_FLAGS:
      return {
        ...state,
        deposito: initialState.deposito,
        state: EnumDepositsStates.Stable,
        modal: { isOpen: false },
        productId: undefined,
        error: "",
      };
    case TRY_AGAIN_PAYMENT:
      return {
        ...state,
        state: EnumDepositsStates.Stable,
        error: "",
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        method: EnumDepositsTab.CARTAO,
        state: EnumDepositsStates.PaymentForm,
        productId: payload?.productId,
      };
    case OPEN_MODAL_DEPOSITS:
      return {
        ...state,
        modal: { isOpen: true },
      };
    case CLOSE_MODAL_DEPOSITS:
      return initialState;
    case DEPOSITS:
      return {
        ...state,
        loading: true,
        state: EnumDepositsStates.LoadingOrder,
      };
    case DEPOSITS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload?.message,
        state: EnumDepositsStates.ErrorOrder,
        productId: undefined,
      };
    case DEPOSITS_FULLFILLED:
      return {
        ...state,
        error: "",
        loading: false,
        deposito: payload?.deposit,
        method:
          payload?.deposit?.payment_type === "PIX" ? EnumDepositsTab.PIX : EnumDepositsTab.CARTAO,
        state: EnumDepositsStates.AfterOrder,
      };
    default:
      return state;
  }
}
