import React, { FC } from "react";
import SmartLink from "../../components/SmartLink";

import icHome1 from "../../assets/ic-home-1.png";
import icHome2 from "../../assets/ic-home-2.png";
import icHome3 from "../../assets/ic-home-3.png";
import icHome4 from "../../assets/ic-home-4.png";
import icHome5 from "../../assets/ic-home-5.png";

import "./Lobby.scss";

const Lobby: FC = () => {
  return (
    <div className="smart-links row flex-wrap">
      <SmartLink text="Jogos de Bingo" imagem={icHome1} link="categoria/jogos-de-bingo" />
      <SmartLink text="Slots e Cassino" imagem={icHome2} link="categoria/slots-cassino" />
      <SmartLink
        text="Jogos de Crash"
        styleImage={{ height: 70, transform: "translateY(0px)" }}
        imagem={icHome3}
        link="categoria/jogos-de-crash"
      />
      <SmartLink text="Jogos Originais" imagem={icHome4} link="categoria/jogos-originais" />
      <SmartLink
        text="Jogos Ao Vivo e Esportes"
        imagem={icHome5}
        styleImage={{ transform: "translateY(0px)" }}
        link="categoria/apostas-esportivas"
      />
    </div>
  );
};

export default Lobby;
