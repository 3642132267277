import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../state/ducks/types";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import { formatDigits } from "../../utils/Functions";

import { ROUTE_PERFIL, ROUTE_SAQUE } from "../../config/routes";

import { openModalAction } from "../../state/ducks/deposits/actions";

import phAvatar from "../../assets/phAvatar.jpg";
import Game from "./Game";

const MenuLoggedIn: FC = () => {
  const dispatch = useDispatch();
  const {
    player: { nickname, accounts, avatar, level },
    loggedIn,
  } = useSelector((state: IRootState) => state.global);

  const findAccountValue = useCallback(
    (type: string) => {
      return accounts.find((account) => account.accountType.code === type)?.balance ?? 0;
    },
    [accounts]
  );

  const [bonus, setBonus] = useState<number>(0);
  const [premios, setPremios] = useState<number>(0);
  const [saldo, setSaldo] = useState<number>(0);

  useEffect(() => {
    setBonus(findAccountValue("BNS"));
    setPremios(findAccountValue("PRM") + findAccountValue("PRC"));
    setSaldo(findAccountValue("DPS"));
  }, [accounts, findAccountValue]);

  return (
    <div className="menuLoggedIn">
      <div className="container row aic">
        <div className="left row aic">
          <div className="name">
            <b>Olá, {nickname}</b> <br />
            <i>Vamos nos divertir?</i>
          </div>
          <div className="row aic text-lucky">
            <Game code='bonus_wheel' height={55} />
          </div>
          {loggedIn && (
            <div className="d-none d-m-block container-avatar">
              <Link to={ROUTE_PERFIL}>
                <div className="avatar">
                  <img src={avatar?.image_url?.toString() ?? phAvatar} alt={nickname} />
                  <div className="avatar-level">
                    <div className="label" style={{ backgroundColor: level?.color ?? "#000" }}>
                      {level?.name}
                    </div>
                    {level?.image_url && (
                      <div className="icon">
                        <img src={level?.image_url} alt={level?.name} />
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="right row">
          <div className="btns row aic col-m-25 col-m-last justify-content-m-between">
            <div className="values row justify-space-around aic">
              <div className="item column aic jcc align-items-m-start">
                <span className="title">Reais</span>
                <div className="row aic">
                  <span className="ic ic-cifrao"></span>
                  {formatDigits(saldo)}
                </div>
              </div>
              <div className="item column aic jcc align-items-m-start">
                <span className="title">Bônus</span>
                <div className="row aic">
                  <span className="ic ic-bonus"></span>
                  {formatDigits(bonus)}
                </div>
              </div>
              <div className="item column aic jcc align-items-m-start">
                <span className="title">Prêmios</span>
                <div className="row aic">
                  <span className="ic ic-premios"></span>
                  {formatDigits(premios)}
                </div>
              </div>
            </div>
            <div className="row btns">
              <Button
                onClick={() => dispatch(openModalAction())}
                text="Depositar"
                buttonStyle={{
                  borderRadius: 5,
                  background: "#4f0b85",
                  border: "0",
                }}
                fontStyle={{
                  fontWeight: 600,
                  color: "#FFF",
                  padding: "0 5px",
                }}
              />
              <Link to={ROUTE_SAQUE}>
                <Button
                  text="Sacar"
                  buttonStyle={{
                    borderRadius: 5,
                    background: "#4f0b85",
                    border: "0",
                  }}
                  fontStyle={{
                    fontWeight: 600,
                    color: "#FFF",
                    padding: "0 5px",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuLoggedIn;
