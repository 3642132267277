import React, { useEffect } from "react";
import { IAttributes } from "../../global";
import "./Banner.scss";

import Slider from "react-slick";

import { useDispatch, useSelector } from "react-redux";
import { globalGetBanners } from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";

interface IBannerProps extends IAttributes {
  bannerStyle?: React.CSSProperties;
}

const Banner: React.FC<IBannerProps> = () => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { banners } = useSelector((state: IRootState) => state.global);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalGetBanners());
  }, [dispatch]);

  return (
    <Slider className="banner" {...settings}>
      {banners?.map((banner) => (
        <a key={banner.id} href={banner.link} target="_blank" rel="noreferrer">
          <div className="item">
            <div
              className="image column justify-content-end"
              style={{ backgroundImage: `url(${banner.image_url})` }}
            ></div>
          </div>
        </a>
      ))}
    </Slider>
  );
};

export default Banner;
