import React, { FC, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import "react-confirm-alert/src/react-confirm-alert.css";

import { IRootState } from "../../state/ducks/types";
import { EnumPlayerLevels, EnumExchangeType } from "../../state/ducks/global/types";
import { globalExchange, globalReset, globalLevels } from "../../state/ducks/global/actions";

import "./Troquenivel.scss";
import { formatCurrency } from "../../utils/Functions";
import Input from "../../components/Input";
import Button from "../../components/Button";

const Troquenivel: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const {
    levels,
    exchangeSuccess,
    error,
    sending,
    player: { level, bets_total },
  } = useSelector((state: IRootState) => state.global);

  const [barWidth, seBarWidth] = useState<number>(0);
  const [pix, setPix] = useState<string>("");
  const [pixType, setPixType] = useState<string>("");
  const [solicitarTroca, setSolicitarTroca] = useState<boolean>(false);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const params = {
      type: EnumExchangeType.LEVEL,
      pix_key: pix,
      pix_key_type: pixType,
    };
    dispatch(globalExchange(params));
  };

  const handleTrade = () => {
    setSolicitarTroca(true);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (level?.name) {
      let bar: number = 0;
      let fraction: number = 100 / 6;
      switch (level?.name) {
        case EnumPlayerLevels.ALLSTAR:
          bar = 100;
          break;
        case EnumPlayerLevels.LENDA:
          bar = fraction * 5;
          break;
        case EnumPlayerLevels.MESTRE:
          bar = fraction * 4;
          break;
        case EnumPlayerLevels.PRO:
          bar = fraction * 3;
          break;
        case EnumPlayerLevels.AMADOR:
          bar = fraction * 2;
          break;
        case EnumPlayerLevels.INTERMEDIARIO:
          bar = fraction;
          break;
        default:
          bar = 0;
      }
      seBarWidth(bar);
    }
  }, [level]);

  useEffect(() => {
    dispatch(globalReset());
    dispatch(globalLevels());
  }, [dispatch]);

  return (
    <div className="troquenivel">
      <div className="container">
        <h1>
          Jogou e não ganhou?
          <br />
          Não tem problema!
          <br />
          Troque seu nível por dinheiro
        </h1>
        <h2 className="">
          No BINGOTINE você tem mais uma chance de ganhar! Seu{" "}
          <span className="sp-1">
            NÍVEL
            <img src="/xp_icon.png" alt="xp" />
          </span>{" "}
          pode ser trocado por{" "}
          <span className="sp-2">
            <img src="/money_icon.png" alt="xp" />
            DINHEIRO
          </span>{" "}
          de verdade!
        </h2>
        <div className="purple-cont">
          <p>
            Sua quantidade de
            <br />
            Pontos de Experiência:
          </p>
          <span>
            <img src="/xp_icon_alt.png" alt="xp" />
            {bets_total}
          </span>
        </div>
        <h3>
          Cada aposta efetuada no site vale 1<img src="/xp_icon.png" alt="xp" />.
        </h3>

        <div className="status-line row justify-content-between">
          <div className="line-bar">
            <div className="bar" style={{ width: `${barWidth}%` }}></div>
          </div>
          {levels &&
            levels.map((item, k) => (
              <div className="item column aic" key={k}>
                <span className="name">{item.name}</span>
                <img src={item.image_url} alt={item.name} />
                {item.value > 0 && (
                  <span className="value">
                    <img width="22" src="money_icon.png" alt="Seu nível vale dinheiro" />{" "}
                    {formatCurrency(item.value)}
                  </span>
                )}
                <span className="value row aic">
                  <img width="20" src="xp_icon.png" alt="Cifrão" /> {item.bets}
                </span>
              </div>
            ))}
        </div>
        {bets_total !== undefined && bets_total >= level.bets && (
          <>
            <h2>
              Você está no nível <span className="sp-1">{level.name.toLocaleUpperCase()}</span> e
              pode
              <br />
              trocá-lo por{" "}
              <span className="sp-2">
                {" "}
                <img src="/money_icon.png" alt="xp" /> {formatCurrency(level.value)}
              </span>
              .
            </h2>
            <button className="button" onClick={handleTrade}>
              <img src="/sort_arrow.png" alt="arrow" />
              SOLICITAR TROCA
            </button>
          </>
        )}
        {bets_total !== undefined && bets_total < level.bets && (
          <h2>
            Você ainda não pode solicitar a troca. Faltam{" "}
            <span>
              {level.bets - bets_total} <img src="/xp_icon.png" alt="xp" />
            </span>
          </h2>
        )}
        <div className="solicitar-troca" ref={ref}>
          {solicitarTroca && (
            <div className="wrap-form">
              <form onSubmit={submitHandler}>
                <div className="">
                  <div className="col col-25 col-m-25 col-margin-0">
                    <label htmlFor="pix_key_type">Tipo da chave Pix</label>
                  </div>
                  <div className="col col-25 col-last col-m-25">
                    <select
                      id="pix_key_type"
                      className="input"
                      required
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setPixType(e.target.value)
                      }
                    >
                      <option value="">Selecione a opção</option>
                      <option value="CPF/CNPJ">CPF/CNPJ</option>
                      <option value="E-MAIL">E-MAIL</option>
                      <option value="TELEFONE">TELEFONE</option>
                      <option value="CHAVE ALEATÓRIA">CHAVE ALEATÓRIA</option>
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="col col-25 col-m-25 col-margin-0">
                    <label htmlFor="pix">Informe o Pix</label>
                  </div>
                  <div className="col col-25 col-last col-m-25">
                    <Input
                      id="pix"
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPix(e.target.value)}
                    />
                  </div>
                </div>
                {error && <div className="msg error">{error}</div>}
                {exchangeSuccess && (
                  <div className="msg success">Solicitação enviada com sucesso</div>
                )}
                <Button text={sending ? "ENVIANDO DADOS..." : "SOLICITAR SAQUE"} />
              </form>
            </div>
          )}
        </div>

        {solicitarTroca && (
          <div className="text">
            <span>Ao efetuar a troca, seu nível volta a ser iniciante.</span>
            <span>
              Caso tenha um saque em processamento, seu
              <br />
              nível não pode ser trocado.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Troquenivel;
