import React, { FC, Fragment, useEffect, useState } from "react";
import { IRootState } from "../../state/ducks/types";
import { gamesCategories, getGameUrl } from "../../state/ducks/games/actions";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { isMobile } from "react-device-detect";
import { ReactComponent as Spinner } from "../../assets/tail-spin.svg";

import "./ListaCategorias.scss";

export interface IListaCategoriasProps {
  limit: boolean;
  value: number;
  slugCategoria?: string;
}

const ListaCategorias: FC<IListaCategoriasProps> = ({ limit, value, slugCategoria }) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state: IRootState) => state.global);

  const { loadingGames, message, categories, gameUrl } = useSelector(
    (state: IRootState) => state.games
  );

  const [gameRedirectControl, setGameRedirectControl] = useState<boolean>(false);
  const [gameRedirectLink, setGameRedirectLink] = useState<string>("");

  const [popupGame, setPopupGame] = useState<Window | null>();
  const [jogoUrl, setJogoUrl] = useState<string>("");
  const [gameId, setGameId] = useState<number>(0);

  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);

  useEffect(() => {
    dispatch(gamesCategories(limit, value));
  }, [dispatch, limit, value]);

  useEffect(() => {
    if (gameUrl.url !== "") {
      if (gameUrl.popup === 1) {
        setJogoUrl(gameUrl.url);
        setGameRedirectLink("");
        setGameRedirectControl(false);
      } else {
        setJogoUrl("");
        setGameRedirectLink(`/${gameUrl.url}`);
        setGameRedirectControl(true);
      }
    }
  }, [gameUrl, dispatch]);

  const openJogo = (gameId: number, popup: boolean) => {
    setGameId(gameId);
    if (!loggedIn) {
      setRedirectLogin(true);
    } else {
      if (popup && !isMobile) {
        const h = window.screen.height * 0.7;
        const w = h * (16 / 9);
        const left = window.screen.width / 2 - w / 2;
        const top = window.screen.height / 2 - h / 2;
        const popup = window.open(
          "",
          !isMobile ? "game" : "_blank",
          !isMobile
            ? `location=no,toolbar=0,status=0,width=${w},height=${h},left=${left},top=${top}`
            : ""
        );
        setPopupGame(popup);
      }
      dispatch(getGameUrl(gameId));
    }
  };

  useEffect(() => {
    if (!loggedIn && popupGame) {
      popupGame.close();
      setRedirectLogin(true);
    }
    if (jogoUrl) {
      if (isMobile) {
        document.location.href = jogoUrl;
      } else {
        if (popupGame) {
          popupGame.location.href = jogoUrl;
        }
      }
    } else if (popupGame && message) {
      popupGame.close();
    }
  }, [jogoUrl, dispatch, popupGame, loggedIn, message]);

  useEffect(() => {
    if (gameUrl.loading === false) {
      setGameId(0);
    }
  }, [gameUrl]);

  return (
    <>
      {message && <div className="msg error">{message}</div>}
      <div className="categories-list">
        {redirectLogin && <Redirect to="/login" />}
        {gameRedirectControl && <Redirect to={gameRedirectLink} />}
        {loadingGames && <div className="msg success">Carregando jogos...</div>}
        {categories.map((games, k) => (
          <Fragment key={k}>
            {(games.slug === slugCategoria || slugCategoria === "") && (
              <Fragment>
                {(slugCategoria !== "" || games?.games.length > 0) && (
                  <div className="categorie" key={k}>
                    <Link to={`/categoria/${games.slug}`}>
                      <div className="title row justify-content-between aic">
                        {games?.icon_url && <img src={games?.icon_url} alt="Ícone" />}
                        <span>{games.description}</span>
                        {games?.icon_url && <img src={games?.icon_url} alt="Ícone" />}
                      </div>
                    </Link>
                    {games?.games.length > 0 && (
                      <div
                        className={classnames("games", {
                          home: slugCategoria === "",
                        })}
                      >
                        {games?.games &&
                          games?.games.map((game, i) => {
                            return (
                              <div
                                className="game"
                                key={`game${i}`}
                                onClick={() => openJogo(game.id, game.gameProvider.external === 1)}
                              >
                                <img
                                  src={game.image_url}
                                  alt={game.name}
                                  className="object-cover"
                                />
                                {gameUrl.loading && gameId === game.id && (
                                  <div className="loading-game">
                                    <Spinner />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default ListaCategorias;
