export const OPEN_MODAL_CHECKOUT = "OPEN_MODAL_CHECKOUT";
export const CLOSE_MODAL_CHECKOUT = "CLOSE_MODAL_CHECKOUT";

export const GET_PACKS_REQUEST = "GET_PACKS_REQUEST";
export const GET_PACKS_FULFILLED = "GET_PACKS_FULFILLED";
export const GET_PACKS_REJECTED = "GET_PACKS_REJECTED";

export const GET_CUPONS_REQUEST = "GET_CUPONS_REQUEST";
export const GET_CUPONS_FULFILLED = "GET_CUPONS_FULFILLED";
export const GET_CUPONS_REJECTED = "GET_CUPONS_REJECTED";

export const RESCUE_CUPOM_REQUEST = "RESCUE_CUPOM_REQUEST";
export const RESCUE_CUPOM_REJECTED = "RESCUE_CUPOM_REJECTED";
export const RESCUE_CUPOM_SUCCESS = "RESCUE_CUPOM_SUCCESS";

export const CHECKOUT_ORDER_REQUEST = "CHECKOUT_ORDER_REQUEST";
export const CHECKOUT_ORDER_FULFILLED = "CHECKOUT_ORDER_FULFILLED";
export const CHECKOUT_ORDER_REJECTED = "CHECKOUT_ORDER_REJECTED";
export const CHECKOUT_PAYMENT_FORM = "CHECKOUT_PAYMENT_FORM";

export enum EnumCheckoutStates {
  LoadingPacks,
  ErrorPacks,
  LoadingOrder,
  ErrorOrder,
  Stable,
  AfterOrder,
  PaymentForm,
  RequestCupom,
  RescuedCupom,
  RescuingCupom,
}

export enum EnumCheckoutType {
  Produtos = "produtos",
  Cupons = "cupons",
}

export enum EnumCheckoutTab {
  Pix,
  Cupom,
  Cartao,
}

export interface IPack {
  descricao: string;
  valor: string;
  linkPagamento: string;
  id: number;
  [key: string]: any;
}

export interface ICupons {
  descricao: string;
  valor: string;
  id: number;
  [key: string]: any;
}

export interface ICuponsGrupos {
  id: number;
  descricao: string;
  cupons: ICupons[];
}

export interface ICheckoutState {
  modal: {
    isOpen: boolean;
  };
  packs?: IPack[];
  cupons?: ICuponsGrupos[];
  items: ICheckoutItem[];
  state: EnumCheckoutStates;
  error?: string;
  order: any;
  card: ICheckoutCard | undefined;
  method: string;
  type: string;
  checkoutType?: EnumCheckoutType;
  cupom?: ICupons;
}

export interface ICheckoutItem {
  id: number;
  quantity: number;
  price: number;
  description: string;
}

export interface ICheckoutCard {
  cvc?: string;
  expiry?: string;
  name?: string;
  number?: string;
  instalments: number;
  issuer?: string;
  cpf?: string;
  token?: string;
}

export interface ICheckoutOrderRequest {
  type: typeof CHECKOUT_ORDER_REQUEST;
  payload: {
    checkout: ICheckoutItem[];
    card: ICheckoutCard | undefined;
    method: string;
    type: string;
  };
}

export interface IRescueCupomRequest {
  type: typeof RESCUE_CUPOM_REQUEST;
  payload: {
    codigo: string;
  };
}
