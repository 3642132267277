import React, { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Link from "../../components/Link";
import { IRootState } from "../../state/ducks/types";
import { getGameCards } from "../../state/ducks/gameCards/actions";

import CartelaItem from "./CartelaItem";

import "./EscolhaCartelas.scss";
import { formatCurrency } from "../../utils/Functions";

const EscolhaCartelas: FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(1);

  const { cards } = useSelector((state: IRootState) => state.gameCards);

  useEffect(() => {
    dispatch(getGameCards("bngdog"));
  }, [dispatch]);

  useEffect(() => {}, [cards]);

  return (
    <div className="escolha-cartelas">
      <div className="container">
        <div className="wmenu">
          <div className="row flex-m-wrap">
            <div className="menu menu-1 row jcc aic" onClick={() => setActiveTab(1)}>
              <span>Jogar com crédito</span>
              <img src="/cifrao.png" alt="Jogar com crédito" />
            </div>
            <div className="menu menu-2 row jcc aic flex-grow-1" onClick={() => setActiveTab(2)}>
              <span>Escolha o valor da sua premiação</span>
              <img src="/premio.png" alt="Escolha o valor da sua premiação" />
            </div>
          </div>
        </div>
        <div className="wtabs">
          {activeTab === 1 && (
            <div className="tab tab-credits">
              <div className="row flex-m-wrap">
                {cards &&
                  cards.map((card, k) => (
                    <Fragment key={k}>
                      <CartelaItem
                        image={`/${card.description}-credit.jpg`}
                        value={formatCurrency(card.price)}
                      />
                    </Fragment>
                  ))}
              </div>
              <div className="row row-btns jcc aic">
                <Button text="JOGAR" />
                <Link>Comprar crédito</Link>
              </div>
            </div>
          )}
          {activeTab === 2 && <div className="tab">Aba valor da premiação</div>}
        </div>
      </div>
    </div>
  );
};

export default EscolhaCartelas;
