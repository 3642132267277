import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import parse from "html-react-parser";

import {
  globalGetNotifications,
  globalGetPlayer,
  globalGetUnreadNotifications,
  globalResetNotifications,
} from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";
import "./Notificacoes.scss";

const Notificacoes: FC = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const {
    loggedIn,
    notifications: {
      meta: { current_page, last_page },
      data,
    },
  } = useSelector((state: IRootState) => state.global);

  const [showPopupVideo, setShowPopupVideo] = useState<boolean>(false);
  const [videoPopup, setVideoPopup] = useState<string>("");

  useEffect(() => {
    if (loggedIn) {
      dispatch(globalGetPlayer());
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    dispatch(globalGetNotifications({ page, per_page: 10, read: true }));
    setTimeout(() => {
      dispatch(globalGetUnreadNotifications());
    }, 500);
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(globalResetNotifications());
  }, [dispatch]);

  const handLoadMore = () => {
    setPage(page + 1);
  };

  const handlerPopupVideo = (video: string) => {
    setShowPopupVideo(!showPopupVideo);
    setVideoPopup(video);
  };

  return (
    <div className="notificacoes">
      {showPopupVideo && (
        <div className="showPopupVideo">
          <span className="close" onClick={() => handlerPopupVideo("")}>
            X
          </span>

          <video src={videoPopup} controls></video>
        </div>
      )}
      <div className="container">
        <h1>Notificações</h1>
        <hr />
        <div className="not-cont">
          {data.map((item) => (
            <div className="fm-item" key={item.id}>
              <div>{item.read === 0 && <span></span>}</div>
              <div
                style={{ backgroundImage: `url(${item?.image_url ?? "/bingotine.png"})` }}
                className="image"
              ></div>
              <p>
                {parse(item.message)}
                <br />
                {item?.notification?.code === "WELCOME" && (
                  <div className="links">
                    <div
                      className="link"
                      onClick={() =>
                        handlerPopupVideo("https://assets2.bingotine.com/media/videos/video1.mp4")
                      }
                    >
                      🍀 Bingotine
                    </div>
                    <div
                      className="link"
                      onClick={() =>
                        handlerPopupVideo("https://assets2.bingotine.com/media/videos/video1.mp4")
                      }
                    >
                      🍀 Como ganhar bônus
                    </div>
                    <div
                      className="link"
                      onClick={() =>
                        handlerPopupVideo("https://assets2.bingotine.com/media/videos/video1.mp4")
                      }
                    >
                      🍀 Depositar
                    </div>
                    {/* <div
                      className="link"
                      onClick={() =>
                        handlerPopupVideo("https://assets2.bingotine.com/media/videos/video1.mp4")
                      }
                    >
                      🍀 Sacar
                    </div> */}
                    <div
                      className="link"
                      onClick={() =>
                        handlerPopupVideo("https://assets2.bingotine.com/media/videos/video1.mp4")
                      }
                    >
                      🍀 Faça saques sem ganhar
                    </div>
                  </div>
                )}
                <span className="mobile">{item.created_at}</span>
              </p>
              <h3 className="desktop">{item.created_at}</h3>
            </div>
          ))}
          {current_page < last_page && (
            <Button onClick={() => handLoadMore()}>Carregar mais</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notificacoes;
