import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

import { ROUTE_TERMOS_CONDICOES, ROUTE_TROQUENIVEL } from "../../config/routes";

import SpanLink from "../../components/Link";
import Input from "../../components/Input";
import Button from "../../components/Button";
import NivelItem from "./NivelItem";

import "./Saque.scss";
import { IRootState } from "../../state/ducks/types";
import { formatCurrency } from "../../utils/Functions";

import { postWithdraw, resetWithdraw } from "../../state/ducks/withdraw/actions";
import { EnumWithDrawType } from "../../state/ducks/withdraw/types";

import { globalLevels } from "../../state/ducks/global/actions";
import ApiClient from "../../services/ApiClient";

const Saque: FC = () => {
  const dispatch = useDispatch();

  const {
    levels,
    player,
    player: { accounts, bets_total, drawable_balance },
  } = useSelector((state: IRootState) => state.global);

  const { loading, error, success } = useSelector((state: IRootState) => state.withdraw);

  const [valor, setValor] = useState<string>("");
  const [pix, setPix] = useState<string>("");
  const [pixType, setPixType] = useState<string>("");
  const [withdrawLock, setWithdrawLock] = useState<boolean>(true);
  const [saqueMinimo, setSaqueMinimo] = useState<string>("");

  const [barWidth, setBarWidth] = useState<number>(0);

  const findAccountValue = (type: string): number => {
    return accounts.find((account) => account.accountType.code === type)?.balance ?? 0;
  };

  const premiosBingo = findAccountValue("PRM");
  const premiosSlots = findAccountValue("PRC");

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(postWithdraw(valor, player.email, pix, pixType, EnumWithDrawType.WITHDRAW_PREMIO));
  };

  useEffect(() => {
    statusBarDraw(bets_total);
  }, [bets_total]);

  useEffect(() => {
    const loadWithdrawLock = async () => {
      try {
        const withdrawLock = await ApiClient.get(`settings/withdraw_lock`);
        setWithdrawLock(withdrawLock.data ? true : false);
      } catch (error) {
        console.log(error);
      }
    };
    loadWithdrawLock();

    const loadSaqueMinimo = async () => {
      try {
        const minimumWithdrawal = await ApiClient.get(`settings/minimum_withdrawal`);
        setSaqueMinimo(minimumWithdrawal.data);
      } catch (error) {
        console.log(error);
      }
    };
    loadSaqueMinimo();
  }, []);

  const statusBarDraw = (bets: number | undefined) => {
    if (bets) {
      let width: number = 0;
      let full: number = 30000;

      width = (bets * 100) / full;
      setBarWidth(width);
    }
  };

  useEffect(() => {
    dispatch(resetWithdraw());
    dispatch(globalLevels());
  }, [dispatch]);

  return (
    <div className="saque">
      <div className="container">
        <h1>Saque</h1>
        <div className="row info flex-wrap flex-m-column">
          <div className="col col-13 col-m-25 col-m-last col-adjust-1 col-margin-0">
            <div className="item row justify-content-between col-13 col-m-12 cont-saldo">
              <div className="col-9 col-m-25 col-m-last">
                <h4>
                  Saldo prêmios
                  <br /> BINGO:
                </h4>
              </div>
              <div className="col-25 col-m-25">
                <span>
                  <img src="/money_icon.png" alt="moeda" />
                  <p>{formatCurrency(premiosBingo)}</p>
                </span>
              </div>
            </div>
            <div className="item row justify-content-between col-13 col-m-12 col-m-last cont-saldo">
              <div className="col-25  col-m-25">
                <h4>
                  Saldo prêmios
                  <br /> SLOTS:
                </h4>
              </div>
              <div className="col-25 col-m-25">
                <span>
                  <img src="/money_icon.png" alt="moeda" />
                  <p>{formatCurrency(premiosSlots)}</p>
                </span>
              </div>
            </div>
            {withdrawLock && (
              <>
                <div style={{ margin: 0 }} className="item d-m-block desktop align-left">
                  <div className="col-13 col-last row aic">
                    <span>
                      Alcance{" "}
                      <span>
                        <img src="/xp_icon.png" alt="xp" />
                        Pontos de Experiência
                      </span>{" "}
                      para
                      <br />
                      saques de Slots e Cassino.
                    </span>
                  </div>
                </div>
                <hr />
                <div className="item  desktop align-left">
                  <div className="col-12 col-last row aic">
                    <p>
                      Saques de jogos Bingotine e <br />
                      Bingo <span>liberados</span>.
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="item col-25 container-p desktop">
              <p>
                Sua quantidade de
                <br />
                Pontos de Experiência:
              </p>
              <span>
                <img src="/xp_icon_alt.png" alt="xp" />
                {bets_total}
              </span>
            </div>
            <div className="item desktop align-left">
              <div className="col-14 col-last row aic">
                <span>
                  Cada aposta efetuada no site vale 1
                  <img className="xp" src="/xp_icon.png" alt="xp" />.
                </span>
              </div>
            </div>
          </div>
          <div className="col col-12 col-last col-m-25 col-m-last col-adjust-2">
            <div className="wrap-form">
              <form onSubmit={submitHandler}>
                <div className="row saque-total">
                  <div className="col col-15 col-m-25 col-m-last col-margin-0">
                    <h3>
                      Saldo total disponível
                      <br />
                      para saque:
                    </h3>
                  </div>
                  <div className="col col-10 col-m-25 col-last col-margin-0">
                    <span>
                      <img src="/money_icon.png" alt="moeda" />
                      <p>{formatCurrency(drawable_balance)}</p>
                    </span>
                  </div>
                </div>
                <h2>SACAR</h2>
                <div className="">
                  <div className="col col-25 col-m-25 col-margin-0">
                    <label htmlFor="valor">Informe o valor</label>
                  </div>
                  <div className="col col-25 col-last col-m-25">
                    <CurrencyInput
                      id="valor"
                      name="valor"
                      allowDecimals={true}
                      decimalSeparator={","}
                      groupSeparator={"."}
                      onValueChange={(value) => setValor(value ?? "")}
                      value={valor}
                      decimalScale={2}
                      required
                      className="input"
                      style={{ textAlign: "right" }}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="col col-25 col-m-25 col-margin-0">
                    <label htmlFor="pix_key_type">Tipo da chave Pix</label>
                  </div>
                  <div className="col col-25 col-last col-m-25">
                    <select
                      id="pix_key_type"
                      className="input"
                      required
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setPixType(e.target.value)
                      }
                    >
                      <option value="">Selecione a opção</option>
                      <option value="CPF/CNPJ">CPF/CNPJ</option>
                      <option value="E-MAIL">E-MAIL</option>
                      <option value="TELEFONE">TELEFONE</option>
                      <option value="CHAVE ALEATÓRIA">CHAVE ALEATÓRIA</option>
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="col col-25 col-m-25 col-margin-0">
                    <label htmlFor="pix">Informe o Pix</label>
                  </div>
                  <div className="col col-25 col-last col-m-25">
                    <Input
                      id="pix"
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPix(e.target.value)}
                    />
                  </div>
                </div>
                {error && <div className="msg error">{error}</div>}
                {success && <div className="msg success">Pedido realizado com sucesso</div>}
                <Button text={loading ? "ENVIANDO DADOS..." : "SOLICITAR SAQUE"} />
                <div className="wbtn">
                  <Link to={ROUTE_TROQUENIVEL}>
                    <SpanLink>
                      <span>TROCAR</span>
                      <img src="/xp_icon_alt.png" alt="xp" />
                      <span>NÍVEL POR DINHEIRO</span>
                    </SpanLink>
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div className="col-25 mob-it-desk col-last mobile">
            {withdrawLock && (
              <>
                <div style={{ margin: 0 }} className="item d-m-block">
                  <div className="col-25 col-last row aic">
                    <span>
                      Alcance{" "}
                      <span>
                        <img src="/xp_icon.png" alt="xp" />
                        Pontos de Experiência
                      </span>{" "}
                      para
                      <br />
                      saques de Slots e Cassino.
                    </span>
                  </div>
                </div>
                <hr />
                <div className="item ">
                  <div className="col-25 col-last row aic">
                    <p>
                      Saques de jogos Bingotine e <br />
                      Bingo <span>liberados</span>.
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="item  container-p">
              <p>
                Sua quantidade de
                <br />
                Pontos de Experiência:
              </p>
              <span>
                <img src="/xp_icon_alt.png" alt="xp" />
                {bets_total}
              </span>
            </div>
            <div className="item ">
              <div className="col-25 col-last row aic">
                <span>
                  Cada aposta efetuada no site vale 1
                  <img className="xp" src="/xp_icon.png" alt="xp" />.
                </span>
              </div>
            </div>
          </div>
          {withdrawLock && (
            <div className="status-line-cont">
              <h3>Seu nível</h3>
              <div className="status-line row justify-content-between">
                <div className="line-bar">
                  <div className="bar" style={{ width: `${barWidth}%` }}></div>
                </div>
                {levels &&
                  levels.map((item, k) => (
                    <NivelItem
                      key={k}
                      title={item.name}
                      imagem={item.image_url}
                      coin={item.bets}
                      value={item.amount}
                      withdrawLock={withdrawLock}
                    />
                  ))}
              </div>
            </div>
          )}
          <div className="item money-banner">
            <Link to={ROUTE_TROQUENIVEL}>
              <SpanLink>
                <h3>
                  Seu NÍVEL também
                  <br />
                  vale DINHEIRO!
                </h3>
                <h4>
                  São muito mais chances de você
                  <br />
                  ganhar! Clique e confira!
                </h4>
              </SpanLink>
            </Link>
          </div>
        </div>

        <div className="text">
          <span>• Saque mínimo: {saqueMinimo && formatCurrency(parseFloat(saqueMinimo))} </span>
          <span>• Atenção: a cada saque efetuado sua pontuação é reiniciada </span>
          <span>
            • Após ser feito uma solicitação de saque, aguarde o pagamento para que um novo pedido
            seja efetuado{" "}
          </span>
          {/* <span>
            {" "}
            • ATENÇÃO: Pagamento do prêmio somente ao titular do Cartão de Crédito. <br /> Enviar a
            Chave PIX e a foto do RG ou CNH do Titular do cartão em nosso WhatsApp{" "}
            <a href="tel:+5511991406564">(11) 9.9140-6564</a> <br />
            Caso não envie no prazo de 2 horas, sua solicitação será CANCELADA.{" "}
          </span> */}
          <span>• Todos os saques solicitados serão pagos em até 48h </span>
          <span>
            • Só é possível efetuar o saque de valores referentes a premiações, depósitos e bônus
            não podem ser sacados!{" "}
          </span>
          {/* <span>
            • Para depósitos feitos via cartão de crédito, é necessário o envio da foto da frente do
            cartão e documento de identidade do titular.
          </span> */}
          <span>
            • Para saber mais leia nossos{" "}
            <Link to={ROUTE_TERMOS_CONDICOES}>Termos e Condições</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Saque;
