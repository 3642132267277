import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGameCodeUrl } from "../../state/ducks/games/actions";
import { IRootState } from "../../state/ducks/types";

import "./Pragmatic.scss";

const Pragmatic = () => {
  const dispatch = useDispatch();

  const { id }: { id: string } = useParams();

  const { gameUrl } = useSelector((state: IRootState) => state.games);

  const [iframUrl, setIframUrl] = useState<string>("/play.html");

  useEffect(() => {
    if (id !== "") {
      dispatch(getGameCodeUrl(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (gameUrl.url !== "" && gameUrl !== null) {
      setIframUrl(gameUrl.url);
    }
  }, [gameUrl]);

  return (
    <div className="externalGame">
      <iframe src={iframUrl} title="Jogue Agora"></iframe>
    </div>
  );
};

export default Pragmatic;
