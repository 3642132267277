import axios, { AxiosRequestConfig } from "axios";

import { API_URL } from "../config/constants";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookie from "./Cookie";

interface ApiInstanceRequestConfig extends AxiosRequestConfig {
  retryCount?: boolean;
}

const sharedConfig = {
  timeout: 30000,
  retryCount: true,
  headers: {
    "Cache-Control": "no-cache, no-store",
  },
};

function createApiInstance(configs: ApiInstanceRequestConfig) {
  const apiInstance = axios.create({
    withCredentials: true,
    ...sharedConfig,
    ...configs,
  });
  const refreshAuthLogic = (failedRequest: any) =>
    axios
      .post(`${API_URL}token`, { token: Cookie.getReAuthenticationCookie() })
      .then((tokenRefreshResponse) => {
        Cookie.saveAuthenticationCookie(
          tokenRefreshResponse?.data?.token?.token,
          tokenRefreshResponse?.data?.token?.type,
          tokenRefreshResponse?.data?.expires_at?.type
        );
        failedRequest.response.config.headers["x-api-key"] =
          tokenRefreshResponse?.data?.token?.token;
        return Promise.resolve();
      })
      .catch((error) => {
        if (failedRequest.config.url !== "/login") {
          Cookie.clearTokens();
          localStorage.clear();
          window.location.reload();
          return Promise.reject(error);
        }
      });
  createAuthRefreshInterceptor(apiInstance, refreshAuthLogic);
  apiInstance.interceptors.request.use((request) => {
    if (request.url === "/login" || (request.url === "/players" && request.method === "post")) {
    } else {
      request.headers[
        "Authorization"
      ] = `${Cookie.getTypeCookie()} ${Cookie.getAuthenticationCookie()}`;
    }
    return request;
  });

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.code === "ECONNABORTED") {
        window.location.reload();
      } else {
        throw error;
      }
    }
  );

  return apiInstance;
}

export default createApiInstance;
