import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import SpanLink from "../../components/Link";

import { INSTAGRAM_URL, FACEBOOK_URL } from "../../state/ducks/global/types";

import {
  ROUTE_QUEM_SOMOS,
  ROUTE_JOGO_RESPONSAVEL,
  ROUTE_INDIQUE_GANHE,
  ROUTE_TERMOS_CONDICOES,
} from "../../config/routes";

import "./Footer.scss";

const Footer: React.FC = () => {
  const [showMobileNumbers, setShowMobileNumbers] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!load) {
      setTimeout(() => {
        setLoad(true);
      }, 1000);
    }
  }, [load]);

  return (
    <footer>
      <div className="main-footer">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=5511991406564"
          className={classNames("float-whatsapp", { active: load })}
        >
          <img src="/ic-whatsapp.png" alt="Fale conosco através do whatsapp" />
        </a>
        <div className="container row justify-content-between">
          <div className="sitemap row align-items-start flex-m-wrap">
            <div className="column w25">
              <Link to={ROUTE_QUEM_SOMOS}>
                <SpanLink>Quem somos</SpanLink>
              </Link>
              <Link to={ROUTE_TERMOS_CONDICOES}>
                <SpanLink>Termos e condições</SpanLink>
              </Link>
              <Link to={ROUTE_INDIQUE_GANHE}>
                <SpanLink>Indique e ganhe</SpanLink>
              </Link>
              <Link to={ROUTE_JOGO_RESPONSAVEL}>
                <SpanLink>Jogo Responsável</SpanLink>
              </Link>
              <SpanLink click={() => setShowMobileNumbers(!showMobileNumbers)}>Contato</SpanLink>
              <div className={classNames("column", { "d-none": !showMobileNumbers })}>
                <a
                  href="https://api.whatsapp.com/send?phone=5511991406564"
                  target="_blank"
                  rel="noreferrer"
                  className="phonelink"
                >
                  (11) 9.9140-6564
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=5511988830364"
                  target="_blank"
                  rel="noreferrer"
                  className="phonelink"
                >
                  (11) 9.8883-0364
                </a>
              </div>
            </div>
          </div>
          <div className="social w25 row justify-content-end align-items-start">
            <a href={INSTAGRAM_URL} rel="noreferrer" target="_blank" title="Acesse nosso instagram">
              <img src="/instagram.png" alt="Logo instagram" />
            </a>
            <a href={FACEBOOK_URL} rel="noreferrer" target="_blank" title="Acesse nosso facebook">
              <img src="/facebook.png" alt="Logo facebook" />
            </a>
          </div>
        </div>
      </div>
      <div className="info">
        <div className="container row justify-content-between flex-m-column">
          <div className="logos row align-items-center justify-content-m-center flex-m-wrap">
            <img src="/logo-lets-encrypt.png" alt="Lets Encrypt" />
            <img src="/logo-google-safe.png" alt="Google" />
            <img src="/logo-mais-18.png" alt="Mais 18" />
          </div>
          <div className="text">
            2021 Bingotine. Todos os diretos reservados. <br />O Bingotine é um site de
            entretenimento online onde você pode desfrutar dos melhores jogos de Bingo e Cassino com
            total segurança, tanto do seu computador quanto do seu smartphone. Bingotine pertence a
            Bingotine Games e Diversões, uma sociedade de responsabilidade limitada.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
